import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";
import { LIST_BANK,DELETE_BANK } from "../components/ApiUrl";
import moment from "moment";

export default function Banks() {
  const [curr_page, setCurrPage] = useState(1);
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [bankdata, setbankdata] = useState([]);
  const [totalpage, setTotalpage] = useState();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/Banks");
      getBankslist(curr_page);
    } else {
      navigate("/login"); 
    }
  }, []);

  // const onchangeorderstatus = (e) => {
  //   setOrderstatus(e);
  //   getuserslist(curr_page, e.value);
  // };

  const getBankslist = (page) => {
    setLoaded(true);
    fetch(LIST_BANK, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        page_no: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setTotalpage(data.total_page);
          setbankdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };
  
  //Delete Swall
  const onDelete = (id) => {
    const BODY = {
      bank_id: id,
    };
    swal({
      title: "Delete Bank",
      text: "Are you sure want to Delete this Bank ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(DELETE_BANK, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
            getBankslist(curr_page);
              swal("Poof! Your imaginary Bank has been Deleted!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Bank is Stay Safe");
      }
    });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Banks
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                {/* <h5 className="h5 mb-2 text-gray-800">Users</h5> */}

                <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-11 font-weight-bold text-dark">
                      Bank List
                      </h2>
                      <div className="col-md-1">
                        <button className="btn btn-primary" onClick={()=>navigate('/Addbank')}>Add Bank</button>
                        {/* <div className="select-focus">
                          <label
                            className="h6 focus-label"
                            Style="font-weight: bold"
                          >
                            User Role
                          </label>
                          <Select
                            className="floating font-weight-bold text-grey m-0"
                            options={options}
                            onChange={(e) => onchangeorderstatus(e)}
                            value={orderstatus}
                          />
                        </div> */}
                      </div>
                      {/* <Select className="m-0 col-md-3 font-weight-bold text-dark" options={options} onChange={(e)=>onchangeorderstatus(e)}/> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Bank Id</th>
                            <th>Bank Name</th>
                            <th>Bank Account Number</th>
                            <th>Bank Holder Name</th>
                            <th>Country</th>
                            <th>IFSC Code</th>
                            <th>Branch Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {bankdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.bank_id}</td>
                              <td>
                                {item.bank_name
                                  ? item.bank_name
                                  : "Not Filled"}{" "}
                              </td>
                              <td>
                                {item.bank_account_no ? item.bank_account_no : "Not Filled"}
                              </td>
                              <td>
                                {item.bank_account_name ? item.bank_account_name : "Not Filled"}
                              </td>
                              <td>
                                {item.country ? item.country : "Not Filled"}
                              </td>
                              <td>
                                {item.ifsc_code ? item.ifsc_code : "Not Filled"}
                              </td>
                              <td>
                                {item.branch_name ? item.branch_name : "Not Filled"}
                              </td>
                              <td className="justify-content-evenly">
                              <button title="Edit Bank!"
                                  className={"btn btn-outline-info m-1"}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "bank_id",
                                      item.bank_id.toString()
                                    ) || navigate("/editbank")
                                  }
                                >
                                   <FontAwesomeIcon icon={solid("pencil")} />
                                </button>
                                <button
                                  className="btn btn-outline-info m-1"
                                  onClick={() => onDelete(item.bank_id)}
                                >
                                  <FontAwesomeIcon icon={solid("trash")} />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <nav aria-label="...">
                        <ul className="pagination justify-content-end">
                          <li
                            className={
                              curr_page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page - 1) ||
                                getBankslist(curr_page - 1)
                              }
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                            <li
                              className={
                                  "page-item"
                              }
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() =>setCurrPage(curr_page)}
                              >
                                {curr_page}
                              </a>
                            </li>
                          <li
                            className={
                              curr_page === totalpage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page + 1) ||
                                getBankslist(curr_page + 1)
                              }
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
