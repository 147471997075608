import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {
  GET_USERKYC_API,
  VERIFY_USERKYC_API,
  imgUrl,
} from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";
import moment from "moment";
import Select from 'react-select'

export default function Userkycdetails() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [extfile1, setextfile1] = useState("");
  const [extfile2, setextfile2] = useState("");
  const utoken = localStorage.getItem("token");
  const user_id = localStorage.getItem("kycid");
  var noimage = require("../assets/images/noProfile.png");
  var pdfimage = require("../assets/images/PDFplaceholder.png");
  const [menu, setMenu] = useState(false);
  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    console.log("utoken =>>>>>>>>", utoken);
    if (utoken) {
      if (user_id) {
        navigate("/Userkycdetails");
        getuserkycdetails();
      } else {
        navigate("/Userkyc");
      }
    } else {
      navigate("/login");
    }
  }, []);

  const getuserkycdetails = () => {
    setLoaded(true);
    fetch(GET_USERKYC_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.Status === 0) {
        } else if (data.Status === 1) {
          console.log(data.info);
          if (data.info.doc_pic1) {
            var split1 = data.info.doc_pic1.split(".");
            var ext1 = split1[split1.length - 1];
          }
          if (data.info.doc_pic2) {
            var split2 = data.info.doc_pic2.split(".");
            var ext2 = split2[split2.length - 1];
          }
          console.log("file 1 ->>", ext1);
          console.log("file name ->>", imgUrl + userdata.doc_pic1);
          setextfile1(ext1);
          console.log("file 2 ->>", ext2);
          setextfile2(ext2);
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  function onEdit(is_verify) {
    fetch(VERIFY_USERKYC_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        is_verified: is_verify,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          const Toast = swal1.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal1.stopTimer);
              toast.addEventListener("mouseleave", swal1.resumeTimer);
            },
          });

          Toast.fire({
            icon: is_verify === 1 ? "success" : "error",
            title:
              is_verify === 1
                ? "Kyc Verified Successfully"
                : "Kyc Rejected Successfully",
          });
          navigate("/Userkyc");
          localStorage.removeItem("kycid");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  }

  function calculate_age(dob) {
    const dateOFBirth = new Date(dob);
    var diff_ms = Date.now() - dateOFBirth.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <div className="row justify-content-between">
            <div className="col-md-10">
              <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                  <a href="/Userkyc">Users Kyc</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  Kyc Details
                </li>
              </ol>
            </div>
            <div>
              {/* {onedit === true ? null :
              <button className="btn btn-outline-success" onClick={generatePDF}>
                Export PDF
              </button>} */}
            </div>
            <div className="col-md-1"></div>
          </div>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0063AF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid" id="profilecard">
              <div Style="padding:10px;">
                <div className="container rounded bg-white mt-4">
                  <div className="row">
                    <div
                      className="col-md-4 border-right py-5"
                      Style="background-color:#0063AF; margin-bottom:10px!important; margin-top:10px!important;"
                    >
                      <div className="d-flex flex-column text-center py-5">
                        {userdata.profile_pic ? (
                          <img
                            src={imgUrl + userdata.profile_pic}
                            alt="Avatar"
                            className="uavatar mx-auto  mt-5"
                          />
                        ) : (
                          <img
                            src={noimage}
                            alt="Avatar"
                            className="uavatar mx-auto text-light mt-5"
                          />
                        )}
                        <span
                          className="h4 my-2 text-light f-w-bold"
                          Style="font-weight: bold;"
                        >
                          {userdata.user_name}
                        </span>
                        <div className="row my-2">
                          <div
                            className="mr-2 text-center"
                            Style="position:absolute; top:0;"
                          >
                            <button
                              className="btn"
                              type="button"
                              onClick={() =>
                                navigate("/Userkyc") ||
                                localStorage.removeItem("id")
                              }
                            >
                              <FontAwesomeIcon
                                icon={solid("arrow-left")}
                                size="2x"
                                style={{ color: "white" }}
                              />
                            </button>
                          </div>
                          {userdata.date_of_birth != null ? (
                            <>
                              <div
                                className="col-md-6 ml-2"
                                Style="text-align:end; border-right:1px solid white;"
                              >
                                <div>
                                  <span
                                    className="h5 text-light"
                                    Style="font-weight: bold; font-size:15px;"
                                  >
                                    DOB
                                  </span>
                                </div>
                                <span
                                  className="h7 mt-1 text-light"
                                  Style="font-weight: bold;"
                                >
                                  {moment(userdata.date_of_birth).format(
                                    "YYYY MMM DD"
                                  )}
                                </span>
                              </div>
                              <div className="col" Style="text-align:start;">
                                <div>
                                  <span
                                    className="h5 text-light"
                                    Style="font-weight: bold; font-size:15px;"
                                  >
                                    Age
                                  </span>
                                </div>
                                <span
                                  className="h7 text-light"
                                  Style="font-weight: bold;"
                                >
                                  {calculate_age(userdata.date_of_birth)} Years
                                </span>
                              </div>
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8 p-3">
                      {/* {onedit === true ? null :
                      <div className="col-md-12" Style="text-align: -webkit-right;">
                    <button Style="border: 0; background-color: transparent;" onClick={()=>setonedit(true)}><FontAwesomeIcon
                                icon={solid("edit")}
                                size="2x" 
                              /></button>
                              </div>} */}
                      <div class="card-block">
                        <div className="row mr-2">
                          <h6
                            class="col m-b-20 p-b-5 b-b-default f-w-600"
                            Style="font-weight: 600; font-size:25px; color:#121A50;"
                          >
                            Kyc Docments
                            <p Style="position: absolute; font-size:13px; padding-top:4px;">
                              Kyc Status is{" "}
                              {userdata.is_verified === 0
                                ? "Pending"
                                : userdata.is_verified === 2
                                ? "Rejected"
                                : "Verified"}
                            </p>
                          </h6>
                          <div className="row">
                            <button
                              className="mr-2 btn btn-outline-info"
                              onClick={() => navigate('/editkyc')}
                            >
                              <b>Edit Kyc</b>
                            </button>
                            <button
                              className="mr-2 btn btn-outline-danger"
                              onClick={() => onEdit(2)}
                            >
                              <b>Reject Kyc</b>
                            </button>
                            <button
                              className="btn btn-outline-success"
                              onClick={() => onEdit(1)}
                            >
                              <b>Accept Kyc</b>
                            </button>
                          </div>
                        </div>
                        <hr />
                        <div className="row justify-content-between">
                          <div className="col-md-6" Style="">
                            <div>
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:20px; color:#0063AF;"
                              >
                                ID Proof
                              </h7>
                            </div>
                            <div className="mt-3">
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:17px; color:#000;"
                              >
                                {userdata.document_name}
                              </h7>
                            </div>
                            {userdata.doc_pic1 ? (
                              extfile1 === "pdf" ? (
                                <div class="containers">
                                  <img
                                    src={pdfimage}
                                    Style="height:250px; width:100%;"
                                  />
                                  <div class="overlay">
                                    <div class="text">
                                      <a
                                        href={imgUrl + userdata.doc_pic1}
                                        style={{
                                          textDecoration: "none",
                                          fontWeight: "bold",
                                          backgroundColor: "blue",
                                          color: "white",
                                          padding: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {" "}
                                        View pdf
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <img
                                  src={imgUrl + userdata.doc_pic1}
                                  alt="Avatar"
                                  className="mx-auto"
                                  Style="height:250px; width:350px;"
                                />
                              )
                            ) : (
                              <img
                                src={noimage}
                                Style="height:250px; width:35   0px;"
                              />
                            )}
                            <div className="row justify-content-center mt-2">
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:17px; color:#0063AF;"
                              >
                                Doc No :
                              </h7>
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:17px; color:#0063AF; padding-left:5px;"
                              >
                                {userdata.doc_no1}
                              </h7>
                            </div>
                          </div>
                          <div className="col-md-6" Style="">
                            <div>
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:20px; color:#0063AF;"
                              >
                                Address Proof
                              </h7>
                            </div>
                            <div className="justify-content-center mt-3">
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:17px; color:#000;"
                              >
                                {userdata.d_name}
                              </h7>
                            </div>
                            {userdata.doc_pic2 ? (
                              extfile2 === "pdf" ? (
                                <div class="containers">
                                  <img
                                    src={pdfimage}
                                    Style="height:250px; width:100%;"
                                  />
                                  <div class="overlay">
                                    <div class="text">
                                      <a
                                        href={imgUrl + userdata.doc_pic2}
                                        style={{
                                          textDecoration: "none",
                                          fontWeight: "bold",
                                          backgroundColor: "blue",
                                          color: "white",
                                          padding: "10px",
                                          borderRadius: "5px",
                                        }}
                                      >
                                        {" "}
                                        View pdf
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <img
                                  src={imgUrl + userdata.doc_pic2}
                                  alt="Avatar"
                                  className="mx-auto"
                                  Style="height:250px; width:350px;"
                                />
                              )
                            ) : (
                              <img
                                src={noimage}
                                Style="height:250px; width:350px;"
                              />
                            )}
                            <div className="row justify-content-center mt-2">
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:17px; color:#0063AF;"
                              >
                                Doc No :
                              </h7>
                              <h7
                                class="m-b-20 p-b-5 b-b-default f-w-600"
                                Style="font-weight: 600; font-size:17px; color:#0063AF; padding-left:5px;"
                              >
                                {" " + userdata.doc_no2}
                              </h7>
                            </div>
                          </div>
                        </div>
                        {/* <h6
                          class="m-b-20 p-b-5 b-b-default f-w-600"
                          Style="font-weight: 600; font-size:20px; color:#121A50;"
                        >
                          User Personal Information
                        </h6>
                        <hr /> */}
                        <div className="row"></div>
                      </div>
                      {/* {onedit === false ? null :
                      <div className="col-md-12" Style="text-align: -webkit-right;">
                    <button className="btn btn-outline-success" onClick={()=>onEdit(userdata.user_id)}>
                      Save Profile
                    </button>
                    </div>} */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
