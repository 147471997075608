export const baseUrl = "https://iptadmin.instapaytrading.com:8080";
export const imgUrl = "https://iptadmin.instapaytrading.com:8080/";

export const SIGN_IN_API = baseUrl + '/admin_login';
export const LIST_USERS_API = baseUrl + '/list_user';
export const GET_AND_EDIT_USER_API = baseUrl + '/edit_user_profile';
export const DASHBOARD_COUNT_API = baseUrl + '/dashboard_count';
export const GET_USERKYC_API = baseUrl + '/list_user_kyc_details';
export const GET_USERS_API = baseUrl + '/get_user';
export const LIST_USERKYC_API = baseUrl + '/list_kyc_details';
export const VERIFY_USERKYC_API = baseUrl + '/verify_kyc';
export const ADD_BANK = baseUrl + '/add_admin_bank_details';
export const EDIT_BANK = baseUrl + '/edit_admin_bank_details';
export const GET_BANK = baseUrl + '/get_admin_bank_details';
export const LIST_BANK = baseUrl + '/list_admin_bank_details';
export const DELETE_BANK = baseUrl + '/delete_admin_bank';
export const LIST_DEPOSIT_API = baseUrl + '/get_deposite_history';
export const LIST_DEPOSIT_REQUEST_API = baseUrl + '/list_deposite_request';
export const UPDATE_DEPOSIT_STATUS = baseUrl + '/update_payment_confirmed';
export const LIST_WITHDRAWAL_API = baseUrl + '/list_withdraw_history_admin';
export const UPDATE_WITHDRAWAL_STATUS = baseUrl + '/update_withdraw_status';
export const UPDATE_REQUEST_STATUS = baseUrl + '/update_request_status';
export const SEND_REQUEST_API = baseUrl + '/send_request';
export const LIST_REQUEST_API = baseUrl + '/get_request';
export const LIST_REQUEST_MESSAGE_API = baseUrl + '/list_request_message';
export const ADD_SERVICES = baseUrl + '/add_service_company';
export const EDIT_SERVICES = baseUrl + '/edit_service_company';
export const LIST_SERVICES = baseUrl + '/list_service_company';
export const DELETE_SERVICES = baseUrl + '/delete_service_company';
export const ADD_ACCOUNT_BALANCE = baseUrl + '/add_account_balance';
export const EDIT_USER_ACCOUNT = baseUrl + '/edit_user_account';
export const LIST_USER_ACCOUNT = baseUrl + '/list_user_account';
export const DELETE_USER_ACCOUNT = baseUrl + '/delete_user_account';
export const GET_ABOUTUS = baseUrl + '/get_about_us';
export const EDIT_ABOUTUS = baseUrl + '/edit_about_us';
export const LIST_SCREENS = baseUrl + '/list_screen';
export const EDIT_SCREEN = baseUrl + '/edit_screen';
export const ADD_SCREEN = baseUrl + '/add_screen';
export const DELETE_SCREEN = baseUrl + '/delete_screen';
export const SEND_PUSH = baseUrl + '/send_push';
export const ADD_REPORTS_ADMIN = baseUrl + '/add_report_image';
export const SEARCH_USER = baseUrl + '/search_user';
export const SEARCH_KYC = baseUrl + '/search_kyc_details';
export const ADD_REPORT_IMAGE = baseUrl + '/add_report_image';
export const GET_REPORT_IMAGE = baseUrl + '/get_report_image';
export const DELETE_REPORT_IMAGE = baseUrl + '/delete_report_image';
export const EDIT_ADMIN_PROFILE = baseUrl + '/edit_admin_profile';
export const LIST_DOCUMENT = baseUrl + '/get_document';
export const EDIT_KYC = baseUrl + '/edit_kyc';
export const ADD_KYC = baseUrl + '/add_user_kyc_details';
export const SEND_DEPOSIT_REQUEST_API = baseUrl + '/create_deposite_request';