import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import {
  LIST_USERKYC_API,
  SEARCH_KYC,
  imgUrl,
} from "../components/ApiUrl";

export default function Userkyc() {
  const [curr_page, setCurrPage] = useState(1);
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [totalpage, setTotalpage] = useState();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/Userkyc");
      getuserslist(curr_page);
    } else {
      navigate("/login");
    }
  }, []);

  const getuserslist = (page) => {
    setLoaded(true);
    fetch(LIST_USERKYC_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        page_no: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setTotalpage(data.total_page);
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  function handleChange(e) {
    e.preventDefault();
    console.log(e.target.value);
    var val = e.target.value;
    // setLoaded(true);
    val.length === 0 ? getuserslist(1) :
    fetch(SEARCH_KYC, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        search_text : e.target.value,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setTotalpage(0);
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  }

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">
                  Dashboard
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Users Kyc
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                {/* <h5 className="h5 mb-2 text-gray-800">Users</h5> */}

                <div className="card shadow mb-4">
                  <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-9 font-weight-bold text-dark">
                        UserKycList
                      </h2>
                      <div className="col-md-3">
                        <div class="form-floating">
                        <label for="floatingInput">Search Kyc</label>
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            placeholder="Search here..."
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <Select className="m-0 col-md-3 font-weight-bold text-dark" options={options} onChange={(e)=>onchangeorderstatus(e)}/> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Kyc Req Id</th>
                            <th>User id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Kyc Verified</th>
                            <th>Profile Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {userdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.kyc_id}</td>
                              <td>{item.user_id}</td>
                              <td>
                                {item.user_name ? item.user_name : "Not Filled"}{" "}
                              </td>
                              <td>
                                {item.email_id ? item.email_id : "Not Filled"}
                              </td>
                              <td Style="text-align: -webkit-center;">
                                {item.is_verified === 0 ? (
                                  <spam className="badge badge-warning" Style="font-size:15px;">
                                    Pending
                                  </spam>
                                ) : item.is_verified === 1 ? (
                                  <spam className="badge badge-success" Style="font-size:15px;">
                                    Success
                                  </spam>
                                ) : (
                                  <spam className="badge badge-danger" Style="font-size:15px;">
                                    Reject
                                  </spam>
                                )}
                              </td>
                              <td className="justify-content-center">
                                {item.profile_pic ? (
                                  <img
                                    src={imgUrl + item.profile_pic}
                                    alt="Avatar"
                                    class="avatar mx-auto d-block"
                                    Style="height:80px; width:80px;"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                    icon={solid("user-circle")}
                                    size="5x"
                                  />
                                )}
                              </td>
                              <td className="justify-content-evenly">
                                  <button
                                    className={item.is_verified === 0 ? "btn btn-outline-warning m-1" : item.is_verified === 2 ? "btn btn-outline-danger m-1" : "btn btn-outline-success m-1"}
                                    onClick={() =>
                                      localStorage.setItem(
                                        "kycid",
                                        item.user_id.toString()
                                      ) || navigate("/Userkycdetails")
                                    }
                                  >
                                   {item.is_verified === 0 ? 'Pending' : item.is_verified === 2 ? 'Rejected' : 'Verified'}
                                    {/* <FontAwesomeIcon icon={solid("eye")} /> */}
                                  </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <nav aria-label="...">
                        <ul className="pagination justify-content-end">
                          <li
                            className={
                              curr_page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page - 1) ||
                                getuserslist(curr_page - 1)
                              }
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                          <li className={"page-item"}>
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => setCurrPage(curr_page)}
                            >
                              {curr_page}
                            </a>
                          </li>
                          <li
                            className={
                              curr_page === totalpage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page + 1) ||
                                getuserslist(curr_page + 1)
                              }
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
