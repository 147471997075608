import React, { useState, useEffect } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { RotatingLines } from "react-loader-spinner";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { GET_USERS_API, SEND_PUSH } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import swal1 from "sweetalert2";

export default function Notification() {
  const [isLoaded, setLoaded] = useState(false);
  const [menu, setMenu] = useState(false);
  const utoken = localStorage.getItem("token");
  const navigate = useNavigate();
  const [userlist, setUserlist] = useState([]);
  const [req_selectuser, setReqselectuser] = useState(false);
  const [checkvalue, setCheckvalue] = useState(1);
  const [selectedValue, setSelectedValue] = useState([]);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value == 2) {
      setSelectedValue([]);
    }
    setCheckvalue(parseInt(value));
  };

  const selectedusers = (e) => {
    setSelectedValue(Array.isArray(e) ? e.map((x) => x.value) : []);
  };

  const onsubmit = (e) => {
    e.preventDefault();
    if (checkvalue === 1 && selectedValue.length <= 0) {
      setReqselectuser(true);
    } else {
      setReqselectuser(false);
      const BODY = {
        user_id: checkvalue === 2 ? "All" : selectedValue.toString(),
        text: title,
        Message: description,
      };
      console.log(BODY);
      const Toast = swal1.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", swal1.stopTimer);
          toast.addEventListener("mouseleave", swal1.resumeTimer);
        },
      });
      setLoaded(true);
      fetch(SEND_PUSH, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${utoken}`,
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify(BODY),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response", data);
          if (data.Status === 0) {
            Toast.fire({
              icon: "error",
              title: data.Message,
            });
          } else if (data.Status === 1) {
            Toast.fire({
              icon: "success",
              title: "Notification Send Successfully",
            });
            setSelectedValue([]);
            setCheckvalue(1);
          }
          setLoaded(false);
        })
        .catch((err) => {
          console.log(JSON.stringify("error", JSON.stringify(err)));
          Toast.fire({
            icon: "error",
            title: "Server Error",
          });
          setSelectedValue([]);
          setLoaded(false);
        });
    }
  };

  useEffect(() => {
    if (utoken) {
      navigate("/notification");
      getuserslist();
    } else {
      navigate("/login");
    }
  }, []);

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  const getuserslist = () => {
    setLoaded(true);
    fetch(GET_USERS_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setUserlist(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          Style="background-color:#008080;"
          id="accordionSidebar"
        >
          <Sidebar />
          <div className="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol className="breadcrumbs">
              <li className="breadcrumb-item">
                <a href="/dashboard" className="text-dark">
                  Dashboard
                </a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Notification
              </li>
            </ol>
            {isLoaded === true ? (
              <div className="loader">
                <RotatingLines
                  strokeColor="grey"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                <div
                  className="row"
                  Style="justify-content: space-around; margin-top:50px;"
                >
                  <div
                    className="col-md-6"
                    Style="padding:50px; border:1px solid #0063AF; border-left:5px solid #0063AF; box-shadow: 3px 4px 15px 0px #E6E6FA; border-radius:10px;"
                  >
                    <form onSubmit={onsubmit}>
                      <h3 Style="font-weight:bold; color:#0063AF; text-align: -webkit-start; margin-top:-15px;">
                        Send Notification
                      </h3>
                      <div className="mb-3">
                        <label
                          for="exampleFormControlInput1"
                          className="form-label"
                        >
                          Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="exampleFormControlInput1"
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Enter Notification Title"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label
                          for="exampleFormControlTextarea1"
                          className="form-label"
                        >
                          Body
                        </label>
                        <textarea
                          className="form-control"
                          id="exampleFormControlTextarea1"
                          placeholder="Enter Notification Body"
                          onChange={(e) => setDescription(e.target.value)}
                          rows="3"
                          required
                        ></textarea>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value="1"
                          defaultChecked
                          Style="color: #fff;
                          border-color: #7B1FA2;
                          background-color: #008080;"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" for="inlineRadio1">
                          To Selected Users
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio2"
                          value="2"
                          Style="color: #fff;
                      border-color: #7B1FA2;
                      background-color: #008080;"
                          onChange={handleChange}
                        />
                        <label className="form-check-label" for="inlineRadio2">
                          To All Users
                        </label>
                      </div>
                      {checkvalue === 1 ? (
                        <div className="mb-3 mt-2">
                          {/* <label for="exampleFormControlTextarea1" className="form-label">
                    Select Users
                  </label> */}
                          <Select
                            isMulti
                            name="users"
                            value={userlist.filter((obj) =>
                              selectedValue.includes(obj.value)
                            )}
                            options={userlist}
                            onChange={selectedusers}
                            className="dropdown"
                            placeholder="Select Users"
                          />
                          {req_selectuser ? (
                            <text className="text-danger">
                              Please Select Any User
                            </text>
                          ) : null}
                        </div>
                      ) : (
                        <div className="mb-3 mt-2">
                          <spam
                            className="badge badge-success"
                            Style="padding:5px; font-size:14px; font-weight:bold;"
                          >
                            All Users
                          </spam>
                        </div>
                      )}
                      <div Style="text-align:-webkit-start;">
                        <button
                          type="submit"
                          className="btn btn"
                          // onClick={onsubmit}
                          Style="background-color: #0063AF; color:#FFF; font-weight: bold; min-width:100px;"
                        >
                          <FontAwesomeIcon
                            icon={solid("paper-plane")}
                            style={{
                              paddingRight: 5,
                              borderRight: "2px solid white",
                            }}
                          />
                          {"  Send"}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
