import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { EDIT_ADMIN_PROFILE } from "../components/ApiUrl";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";

export default function Editprofileadmin() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);
  var user_id = localStorage.getItem("id");

  useEffect(() => {
    if (utoken) {
      navigate("/myprofile");
      getuserdetails();
    } else {
      navigate("/login");
    }
  }, []);

  const getuserdetails = () => {
    setLoaded(true);
    fetch(EDIT_ADMIN_PROFILE, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setUsername(data.info.email_id);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const edituser = (e) => {
    console.log("called");
    e.preventDefault();
    setLoaded(true);

    var BODY = {
      email_id : username,
      password : password
    };
    fetch(EDIT_ADMIN_PROFILE, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setPassword();
            const Toast = swal1.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", swal1.stopTimer);
                  toast.addEventListener("mouseleave", swal1.resumeTimer);
                },
              });
    
              Toast.fire({
                icon: "success",
                title: "Profile Edited Successfully",
              });
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/dashboard" className="text-dark">
                Dashboard
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              My Profile
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={edituser}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        {/* <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/users")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button> */}
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          My Profile
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Email *
                          </label>
                          <input
                            type="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                            title="Invalid email address"
                            className="form-control"
                            placeholder="Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            New Password *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="New Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            // required
                          />
                        </div>
                      </div>

                      {/* <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Confirm Password *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Confirm Password"
                            value={password}
                            onChange={(e) => setThirdpartypassword(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div> */}
                      <div className="row justify-content-center">
                        <div className="mt-5 mr-2 text-center col-md-3">
                          <button
                            className="btn btn-primary"
                            Style="width:100%;"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}