import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { LIST_REQUEST_MESSAGE_API,SEND_REQUEST_API } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";
import moment from "moment";

export default function Sendfeedback() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [userdetails, setUserdetails] = useState({});
  const [messagelist, setMessagelist] = useState([]);
  const [message, setMessage] = useState('');
  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);
  var ticketid = localStorage.getItem("ticket_id");

  useEffect(() => {
    if (utoken) {
      if(ticketid){
      navigate("/Sendsupportfeedback");
      getmessagelist();
    } else {
      navigate("/Support");
    }
    } else {
      navigate("/login");
    }
  }, []);

  const getmessagelist = () => {
    setLoaded(true);
    fetch(LIST_REQUEST_MESSAGE_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        ticket_id: ticketid,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setUserdetails(data.info);
          setMessagelist(data.info.message_list);
          window.scrollTo(0, document.body.scrollHeight);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const SendMessage = (e) => {
    e.preventDefault();
    // setLoaded(true);
    var BODY = {
      other_id : userdetails.ticket_created_by,
      ticket_id : ticketid,
      msg: message
    };
    fetch(SEND_REQUEST_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          getmessagelist();
          setMessage('');
            const Toast = swal1.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", swal1.stopTimer);
                  toast.addEventListener("mouseleave", swal1.resumeTimer);
                },
              });
    
              Toast.fire({
                icon: "success",
                title: "Send Feedback Successfully",
              });
              // navigate("/users");
              // localStorage.removeItem("id");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/Support" className="text-dark">
                Support Request
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Send Feedback
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={SendMessage}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/Support")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button>
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          Send Feedback
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-8">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Name
                          </label>
                          <h6
                           className="form-control"
                           Style="border:0px; background-color:#fff; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px; border-top-left-radius:0px; border-bottom-left-radius:20px; border-top-right-radius:20px; border-bottom-right-radius:0px; width: max-content; max-width: -webkit-fill-available; overflow: auto;"
                         >{userdetails.name}</h6>
                          <h6
                           Style="border:0px; left:0px; font-size:10px;"
                         >{moment(userdetails.created_at).format('lll')}</h6>
                        </div>
                        <div className="col-md-4"></div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-8">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Email Address
                          </label>
                          <h6
                           className="form-control"
                           Style="border:0px; background-color:#fff; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px; border-top-left-radius:0px; border-bottom-left-radius:20px; border-top-right-radius:20px; border-bottom-right-radius:0px; width: max-content; max-width: -webkit-fill-available; overflow: auto;"
                         >{userdetails.email}</h6>
                         <h6
                           Style="border:0px; left:0px; font-size:10px;"
                         >{moment(userdetails.created_at).format('lll')}</h6>
                        </div>
                        <div className="col-md-4"></div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-8">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Phone Number
                          </label>
                          <h6
                           className="form-control"
                           Style="border:0px; background-color:#fff; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px; border-top-left-radius:0px; border-bottom-left-radius:20px;border-top-right-radius:20px; border-bottom-right-radius:0px; width: max-content; max-width: -webkit-fill-available; overflow: auto;"
                         >
                          {userdetails.phone_number}
                         </h6>
                         <h6
                           Style="border:0px; left:0px; font-size:10px;"
                         >{moment(userdetails.created_at).format('lll')}</h6>
                        </div>
                        <div className="col-md-4"></div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-8">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Subject
                          </label>
                          <h6
                           className="form-control"
                           Style="border:0px; background-color:#fff; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px; border-top-left-radius:0px; border-bottom-left-radius:20px; border-top-right-radius:20px; border-bottom-right-radius:0px; width: max-content; max-width: -webkit-fill-available; overflow: auto;"
                         >{userdetails.subject}</h6>
                         <h6
                           Style="border:0px; left:0px; font-size:10px;"
                         >{moment(userdetails.created_at).format('lll')}</h6>
                        </div>
                        <div className="col-md-4"></div>
                      </div>

                      {messagelist.map((item,key) => 
                        <div key={key} className="row mt-3 col-md-12">
                          {item.request_by === 1 ? null :
                          <>
                           <label
                           className="labels"
                           Style="color:#0063AF; font-size:15px; text-align: -webkit-left;
                           width: -webkit-fill-available;"
                         >
                           {item.user_name+' :)'}
                         </label>
                         <div Style="width: -webkit-fill-available;text-align: -webkit-left;">
                         <h6
                           className="form-control"
                           Style="border:0px; background-color:#fff; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px; border-top-left-radius:0px; border-bottom-left-radius:20px; border-top-right-radius:20px; border-bottom-right-radius:0px; width: max-content; max-width: -webkit-fill-available; overflow: auto; height: auto;"
                         >{item.message_text}</h6>
                         <h6
                           Style="border:0px; left:0px; font-size:10px;"
                         >{moment(userdetails.created_at).format('lll')}</h6></div></> }
                          {item.request_by === 1 ?<>
                          <label
                            className="labels" 
                            Style="color:#0063AF; font-size:15px; right:0px; text-align: -webkit-right;
                            width: -webkit-fill-available;"
                          >
                            (: You
                          </label>
                          <div Style="width: -webkit-fill-available;text-align: -webkit-right;">
                          <h6
                            className="form-control"
                            Style="border:0px; background-color:#0063AF; color:#FFF; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px; border-top-left-radius:20px; border-bottom-left-radius:0px; border-top-right-radius:0px; border-bottom-right-radius:20px; width: max-content; max-width: -webkit-fill-available;
                            overflow: auto; height: auto;"
                          >{item.message_text}</h6>
                          <h6
                           Style="border:0px; left:0px; font-size:10px;"
                         >{moment(userdetails.created_at).format('lll')}</h6>
                          </div>
                        </>:null}
                      </div>
                      )}
                      {userdetails.is_completed === 0 ? 
                      <div className="row py-3">
                      <div className="col-md-11">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type Feedback here..."
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:20px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                        <div className="col-md-1">
                          <button
                            className="btn btn-primary"
                            Style="min-width:50px; height:50px; border-radius:10px;"
                            type="submit"
                          >
                           <i class="fas fa-paper-plane" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>: null}
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}