import React,{useState,useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useLocation } from 'react-router-dom';
const logo = require('../assets/images/logo.png');

export default function Sidebar() {
    const location = useLocation();
    console.log(location.pathname);
   var [activepath,setActivePath] = useState();
   const [toggle,setToggle] = useState(false);
//    console.log(activepath);


useEffect(() => {
    // if(location.pathname === '/dashboard' || location.pathname === '/users' || location.pathname === '/login'){
  console.log('sidebar called');
  }, []);
 

  return (
    <>
      <a className="sidebar-brand d-flex align-items-end justify-content-center" href="/">
          <div className="sidebar-brand-icon" >
          <img src={logo} Style="height:35px; width:35px; resize-mode:contain;" />
          </div>
          <div className="sidebar-brand-text mx-2">IPT</div>
      </a>

      <hr className="sidebar-divider my-0" />

      <li className={location.pathname === '/dashboard' || location.pathname === '/' ? "nav-item active list" : "nav-item list"}>
          <a className="nav-link" href="/dashboard">
              <i className="fas fa-fw fa-tachometer-alt"></i>
              <span Style={location.pathname === '/dashboard' || location.pathname === '/' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}> Dashboard</span></a>
      </li>

      <hr className="sidebar-divider" />

      <div className="sidebar-heading">
          Interface
      </div>

      <li className={location.pathname === '/users' || location.pathname ==='/profile' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/users' || location.pathname === '/profile' ? "nav-link active" : "nav-link"} href="/users">
              <i className="fas fa-users"></i>
              <span Style={location.pathname === '/users' || location.pathname === '/profile' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Users</span>
          </a>
      </li>

       <li className={location.pathname ===  '/Userkyc' || location.pathname === '/Userkycdetails' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/Userkyc' || location.pathname === '/Userkycdetails' ? "nav-link active" : "nav-link"} href="/Userkyc">
             <i class="fas fa-address-card"></i>
              <span Style={location.pathname === '/Userkyc' || location.pathname === '/Userkycdetails' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Users Kyc</span>
          </a>
      </li>

      <li className={location.pathname ===  '/Banks' || location.pathname === '/Addbank' || location.pathname === '/Editbank' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/Banks' || location.pathname === '/Addbank' || location.pathname === '/Editbank' ? "nav-link active" : "nav-link"} href="/Banks">
          <i class="fas fa-landmark"></i>
              <span Style={location.pathname === '/Banks' || location.pathname === '/Addbank' || location.pathname === '/Editbank' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Banks</span>
          </a> 
      </li>

       <li className={location.pathname ===  '/depositrequest' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/depositrequest' ? "nav-link active" : "nav-link"} href="/depositrequest">
             <i class="fas fa-dollar-sign"></i>
              <span Style={location.pathname === '/depositrequest' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Deposit Request</span>
          </a>
      </li>

       <li className={location.pathname ===  '/Deposit' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/Deposit' ? "nav-link active" : "nav-link"} href="/Deposit">
             <i class="fas fa-dollar-sign"></i>
              <span Style={location.pathname === '/Deposit' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Deposit</span>
          </a>
      </li>
      
       <li className={location.pathname ===  '/Withdrawal' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/Withdrawal' ? "nav-link active" : "nav-link"} href="/Withdrawal">
             <i class="fas fa-dollar-sign"></i>
              <span Style={location.pathname === '/Withdrawal' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Withdrawal</span>
          </a>
      </li>

      <li className={location.pathname ===  '/Services' || location.pathname === '/addservice' || location.pathname === '/editservice' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/Services' || location.pathname === '/addservice' || location.pathname === '/editservice' ? "nav-link active" : "nav-link"} href="/Services">
             <i class="fas fa-wrench"></i>
              <span Style={location.pathname === '/Services' || location.pathname === '/addservice' || location.pathname === '/editservice' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Services</span>
          </a> 
      </li>

      <li className={location.pathname ===  '/aboutus' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/aboutus' ? "nav-link active" : "nav-link"} href="/aboutus">
             <i class="fas fa-info-circle"></i>
              <span Style={location.pathname === '/aboutus'  ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Aboutus</span>
          </a> 
      </li>

      <li className={location.pathname ===  '/manageonboarding' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/manageonboarding' ? "nav-link active" : "nav-link"} href="/manageonboarding">
             <i class="fas fa-wrench"></i>
              <span Style={location.pathname === '/manageonboarding'  ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Manage Onboarding</span>
          </a> 
      </li>

      <li className={location.pathname ===  '/notification' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/notification' ? "nav-link active" : "nav-link"} href="/notification">
             <i class="fas fa-bell"></i>
              <span Style={location.pathname === '/notification'  ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Notification</span>
          </a> 
      </li>

      <li className={location.pathname ===  '/myprofile' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/myprofile' ? "nav-link active" : "nav-link"} href="/myprofile">
             <i class="fas fa-user"></i>
              <span Style={location.pathname === '/myprofile'  ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>My Profile</span>
          </a> 
      </li>

       <li className={location.pathname ===  '/Support' || location.pathname === '/Userkycdetail' ? "nav-item active list" : "nav-item list"}>
          <a className={location.pathname === '/Support' || location.pathname === '/Userkycdetails' ? "nav-link active" : "nav-link"} href="/Support">
             <i class="fas fa-question-circle"></i>
              <span Style={location.pathname === '/Support' || location.pathname === '/Userkycdetail' ? "color:#fff; font-weight:bold; font-size:15px;" : "color:#fff"}>Support Request</span>
          </a> 
       </li>

      <hr className="sidebar-divider d-none d-md-block" />
  </>      
  )
}
