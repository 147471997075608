import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";
import { LIST_SERVICES,DELETE_SERVICES, imgUrl} from "../components/ApiUrl";
import moment from "moment";
import ReactHtmlParser from 'react-html-parser';

export default function Services() {
  const [curr_page, setCurrPage] = useState(1);
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [servicesdata, setServicesdata] = useState([]);
  const [totalpage, setTotalpage] = useState();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/Services");
      getServiceslist(curr_page);
    } else {
      navigate("/login"); 
    }
  }, []);

  const getServiceslist = (page) => {
    setLoaded(true);
    fetch(LIST_SERVICES, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        page_no: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setTotalpage(data.total_page);
          setServicesdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };
  
  //Delete Swall
  const onDelete = (id,img) => {
    const BODY = {
      service_id: id,
      service_image : img,
    };
    swal({
      title: "Delete Service",
      text: "Are you sure want to Delete this Service ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(DELETE_SERVICES, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
            getServiceslist(curr_page);
              swal("Poof! Your imaginary Service has been Deleted!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Service is Stay Safe");
      }
    });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                 Services
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-10 font-weight-bold text-dark">
                      Service List
                      </h2>
                      <div className="col-md-2" Style="text-align: -webkit-right;">
                        <button className="btn btn-primary" onClick={()=>navigate('/addservice')}>Add Service</button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Service Id</th>
                            <th>Service Name</th>
                            <th>Service Description</th>
                            <th>Service Image</th>
                            <th>Service on</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {servicesdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.service_id}</td>
                              <td>
                                {item.service_name
                                  ? item.service_name
                                  : "Not Filled"}{" "}
                              </td>
                              <td>
                                {item.service_description ? ReactHtmlParser(item.service_description) : "Not Filled"}
                              </td>
                              <td className="justify-content-center">
                                {item.service_image ? (
                                  <img
                                    src={imgUrl + item.service_image}
                                    alt="Avatar"
                                    class="avatar mx-auto d-block"
                                    Style="height:80px; width:80px;"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                    icon={solid("user-circle")}
                                    size="5x"
                                  />
                                )}
                              </td>
                              <td>
                                {moment(item.created_at).format('ll')}
                              </td>
                              <td className="justify-content-evenly">
                              <button title="Edit Bank!"
                                  className={"btn btn-outline-info m-1"}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "service_id",
                                      item.service_id.toString()
                                    ) || navigate("/editservice")
                                  }
                                >
                                   <FontAwesomeIcon icon={solid("pencil")} />
                                </button>
                                <button
                                  className="btn btn-outline-info m-1"
                                  onClick={() => onDelete(item.service_id,item.service_image)}
                                >
                                  <FontAwesomeIcon icon={solid("trash")} />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <nav aria-label="...">
                        <ul className="pagination justify-content-end">
                          <li
                            className={
                              curr_page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page - 1) ||
                                getServiceslist(curr_page - 1)
                              }
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                            <li
                              className={
                                  "page-item"
                              }
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() =>setCurrPage(curr_page)}
                              >
                                {curr_page}
                              </a>
                            </li>
                          <li
                            className={
                              curr_page === totalpage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page + 1) ||
                                getServiceslist(curr_page + 1)
                              }
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
