import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Dashboard from "./Screens/Dashboard";
import Users from "./Screens/Users";
import Login from "./Screens/Login";
import Pagenotfound from "./Screens/Pagenotfound";
import Profile from "./Screens/Profile";
import Userkyc from "./Screens/Userkyc";
import Deposit from "./Screens/Deposit";
import Kycdetails from "./Screens/Kycdetails";
import Withdrawal from "./Screens/Withdrawal";
import Addthirdparty from "./Screens/Addthirdparty";
import Supportrequest from "./Screens/Supportrequest";
import Sendfeedback from "./Screens/Sendfeedback";
import Banks from "./Screens/Banks";
import Addbank from "./Screens/Addbank";
import Editbank from "./Screens/Editbank";
import Services from "./Screens/Services";
import Addservice from "./Screens/Addservice";
import Editservice from "./Screens/Editservice";
import Addaccount from "./Screens/Addaccount";
import Editaccount from "./Screens/Editaccount";
import Aboutus from "./Screens/Aboutus";
import Onboarding from "./Screens/Onboarding";
import AddScreen from "./Screens/AddScreen";
import EditScreen from "./Screens/EditScreen";
import Notification from "./Screens/Notification";
import Editprofileadmin from "./Screens/Editprofileadmin";
import Editkyc from "./Screens/Editkyc";
import Addkyc from "./Screens/AddKyc";
import Senddepositrequest from "./Screens/Senddepositrequest";
import DepositRequest from "./Screens/Depositrequest";

function App() {
  const isUserAuthenticated = localStorage.getItem('token');
  return (
    <>
      <Router>
        <Routes>
       <Route
       exact
       path="/"
       render={() => {
           return (
             isUserAuthenticated ?
             <Navigate to="/dashboard" /> :
             <Navigate to="/login" /> 
           )
       }}
       element={isUserAuthenticated ?
        <Navigate to="/dashboard" /> :
        <Navigate to="/login" /> }
     />
          <Route exact path="/login" element={<Login />}></Route>
          <Route exact path="/dashboard" element={<Dashboard />}></Route>
          <Route exact path="/users" element={<Users />}></Route>
          <Route exact path="/profile" element={<Profile />}></Route>
          <Route exact path="/Edituser" element={<Addthirdparty />}></Route>
          <Route exact path="/Userkyc" element={<Userkyc />}></Route>
          <Route exact path="/Userkycdetails" element={<Kycdetails />}></Route>
          <Route exact path="/Deposit" element={<Deposit />}></Route>
          <Route exact path="/depositrequest" element={<DepositRequest />}></Route>
          <Route exact path="/Withdrawal" element={<Withdrawal />}></Route>
          <Route exact path="/Support" element={<Supportrequest />}></Route>
          <Route exact path="/Sendsupportfeedback" element={<Sendfeedback />}></Route>
          <Route exact path="/Addbank" element={<Addbank />}></Route>
          <Route exact path="/Editbank" element={<Editbank />}></Route>
          <Route exact path="/Banks" element={<Banks />}></Route>
          <Route exact path="/Services" element={<Services />}></Route>
          <Route exact path="/addservice" element={<Addservice />}></Route>
          <Route exact path="/editservice" element={<Editservice />}></Route>
          <Route exact path="/addaccount" element={<Addaccount />}></Route>
          <Route exact path="/editaccount" element={<Editaccount />}></Route>
          <Route exact path="/aboutus" element={<Aboutus />}></Route>
          <Route exact path="/manageonboarding" element={<Onboarding />}></Route>
          <Route exact path="/AddScreen" element={<AddScreen />}></Route>
          <Route exact path="/EditScreen" element={<EditScreen />}></Route>
          <Route exact path="/notification" element={<Notification />}></Route>
          <Route exact path="/myprofile" element={<Editprofileadmin />}></Route>
          <Route exact path="/editkyc" element={<Editkyc />}></Route>
          <Route exact path="/addkyc" element={<Addkyc />}></Route>
          <Route exact path="/senddepositrequest" element={<Senddepositrequest />}></Route>
          <Route exact path="*" element={<Pagenotfound />}></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
