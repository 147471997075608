import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import {
  GET_AND_EDIT_USER_API,
  LIST_USER_ACCOUNT,
  DELETE_USER_ACCOUNT,
  ADD_REPORT_IMAGE,
  GET_REPORT_IMAGE,
  DELETE_REPORT_IMAGE,
  imgUrl,
} from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import moment from "moment";
import swal from "sweetalert";
import { countries } from "country-data";
import { Document } from 'react-pdf';

export default function Users() {
  const navigate = useNavigate();
  const [curr_page, setCurrPage] = useState(1);
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [userdatas, setUserdatas] = useState([]);
  const utoken = localStorage.getItem("token");
  const [totalpage, setTotalpage] = useState();
  const [serviceimage, setserviceimage] = useState();
  const [img, setImg] = useState();
  const user_id = localStorage.getItem("id");
  var noimage = require("../assets/images/noProfile.png");
  const [menu, setMenu] = useState(false);
  const [report_image_id, setReport_image_id] = useState();

  useEffect(() => {
    console.log("utoken =>>>>>>>>", utoken);
    // console.log(countries.all);
    if (utoken) {
      if (user_id) {
        navigate("/profile");
        getuserdetails();
        getReportimage();
        getaccountlist(curr_page);
      } else {
        navigate("/users");
      }
    } else {
      navigate("/login");
    }
  }, []);

  const getuserdetails = () => {
    setLoaded(true);
    fetch(GET_AND_EDIT_USER_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
        } else if (data.Status === 1) {
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  //Delete Swall
  const onDelete = (id) => {
    const BODY = {
      account_id: id,
    };

    swal({
      title: "Delete Account",
      text: "Are you sure want to Delete this Account ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(DELETE_USER_ACCOUNT, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              getaccountlist(curr_page);
              swal("Poof! Your imaginary Account has been Deleted!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Account is Safe!");
      }
    });
  };

  //Delete Report Swall
  const deleteReportimage = () => {
    const BODY = {
      report_image_id: report_image_id,
      old_report_image: img,
    };

    console.log(BODY);

    swal({
      title: "Delete Report Image",
      text: "Are you sure want to Delete this Report Image ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(DELETE_REPORT_IMAGE, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              setserviceimage();
              setImg();
              swal("Poof! Your imaginary Report Image has been Deleted!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", JSON.stringify(err)));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Report Image is Safe!");
      }
    });
  };

  //GET Report Swall
  const getReportimage = () => {
    const BODY = {
      user_id: user_id,
    };

    fetch(GET_REPORT_IMAGE, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "Content-Type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          var Info = data.info.length <= 0 ? 0 : data.info[0];
          if (Info != 0) {
            setImg(Info.report_image);
            setserviceimage(imgUrl + Info.report_image);
            setReport_image_id(Info.report_image_id);
          }
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  function getaccountlist(c_page) {
    fetch(LIST_USER_ACCOUNT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
        page_no: curr_page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setTotalpage(data.total_page);
          setUserdatas(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  }

  function calculate_age(dob) {
    const dateOFBirth = new Date(dob);
    var diff_ms = Date.now() - dateOFBirth.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  function handleChange(e) {
    console.log(e.target.files);
    // setImg(e.target.files[0]);
    // setserviceimage(URL.createObjectURL(e.target.files[0]));

    const formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("report_image", e.target.files[0]);

    swal({
      title: "Upload Report Image",
      text: "Are you sure want to Upload this Report Image ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(ADD_REPORT_IMAGE, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              setLoaded(false);
            } else if (data.Status === 1) {
              getReportimage();
              swal("Poof! Your imaginary Report Image has been Uploaded!", {
                icon: "success",
              });
            }
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Report Image is not Uploaded!");
      }
    });
  }

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <div className="row justify-content-between">
            <div className="col-md-10">
              <ol class="breadcrumbs">
                <li class="breadcrumb-item">
                  <a href="/users">Users</a>
                </li>
                <li class="breadcrumb-item active" aria-current="page">
                  User Profile
                </li>
              </ol>
            </div>
            <div>
              {/* {onedit === true ? null :
              <button className="btn btn-outline-success" onClick={generatePDF}>
                Export PDF
              </button>} */}
            </div>
            <div className="col-md-1"></div>
          </div>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0063AF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid" id="profilecard">
              <div Style="padding:10px;">
                <div className="rounded ">
                  <div className="row">
                    <div className="col-md-3">
                      <div
                        className="border-right"
                        Style="background-color:#0063AF; margin-bottom:10px!important; margin-right:10px!important;"
                      >
                        <div className="d-flex flex-column text-center py-5">
                          {userdata.profile_pic ? (
                            <img
                              src={imgUrl + userdata.profile_pic}
                              alt="Avatar"
                              className="uavatar mx-auto  mt-5"
                            />
                          ) : (
                            <img
                              src={noimage}
                              alt="Avatar"
                              className="uavatar mx-auto text-light mt-5"
                            />
                          )}
                          <span
                            className="h4 my-2 text-light f-w-bold"
                            Style="font-weight: bold;"
                          >
                            {userdata.user_name}
                          </span>
                          {userdata.city ? (
                            <div className="row justify-content-center">
                              <div className="px-2">
                                <i
                                  class="fas fa-map-marker-alt text-light"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <h6 class="text-light mt-1 f-w-400">
                                  {userdata.city}
                                </h6>
                              </div>
                            </div>
                          ) : null}
                          <div className="row my-2">
                            <div
                              className="mr-2 text-center"
                              Style="position:absolute; top:0;"
                            >
                              <button
                                className="btn"
                                type="button"
                                onClick={() =>
                                  navigate("/users") ||
                                  localStorage.removeItem("id")
                                }
                              >
                                <FontAwesomeIcon
                                  icon={solid("arrow-left")}
                                  size="2x"
                                  style={{ color: "white" }}
                                />
                              </button>
                            </div>
                            {userdata.date_of_birth != null ? (
                              <>
                                <div
                                  className="col-md-6 ml-2"
                                  Style="text-align:end; border-right:1px solid white;"
                                >
                                  <div>
                                    <span
                                      className="h5 text-light"
                                      Style="font-weight: bold; font-size:15px;"
                                    >
                                      DOB
                                    </span>
                                  </div>
                                  <span
                                    className="h7 mt-1 text-light"
                                    Style="font-weight: bold;"
                                  >
                                    {moment(userdata.date_of_birth).format(
                                      "YYYY MMM DD"
                                    )}
                                  </span>
                                </div>
                                <div className="col" Style="text-align:start;">
                                  <div>
                                    <span
                                      className="h5 text-light"
                                      Style="font-weight: bold; font-size:15px;"
                                    >
                                      Age
                                    </span>
                                  </div>
                                  <span
                                    className="h7 text-light"
                                    Style="font-weight: bold;"
                                  >
                                    {calculate_age(userdata.date_of_birth)}{" "}
                                    Years
                                  </span>
                                </div>
                              </>
                            ) : null}
                          </div>
                          {userdata.email_id ? (
                            <div className="row justify-content-center">
                              <div className="px-2">
                                <i
                                  class="fas fa-envelope text-light"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <h6 class="text-light f-w-400">
                                  {userdata.email_id}
                                </h6>
                              </div>
                            </div>
                          ) : null}
                          {userdata.phone_number ? (
                            <div className="row justify-content-center">
                              <div className="px-2">
                                <i
                                  class="fas fa-phone-alt text-light"
                                  aria-hidden="true"
                                ></i>
                              </div>
                              <div>
                                <h6 class="text-light f-w-400">
                                {userdata.country_code || userdata.country_code != null ? countries[userdata.country_code.toString()]
                                    .countryCallingCodes[0] +
                                    " " +
                                    userdata.phone_number : userdata.phone_number}
                                </h6>
                              </div>
                            </div>
                          ) : null}
                          {/* <div className="row justify-content-center">
                          <div className="px-2">
                            Total Amount
                          </div>
                          <div>
                            <h6 class="text-light f-w-400">
                              ${userdata.total_balance}
                            </h6>
                          </div>
                        </div> */}
                        </div>
                      </div>
                      <div Style="">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:18px;"
                          >
                            Report Image
                          </label>
                          <div id="file-upload-form" class="uploader">
                            {!serviceimage ? (
                              <>
                                <input
                                  id="file-upload"
                                  type="file"
                                  name="fileUpload"
                                  accept="image/*"
                                  onChange={handleChange}
                                />

                                <label for="file-upload" id="file-drag">
                                  <div id="start">
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                    <div>Select a file or drag here</div>
                                    <div id="notimage" class="hidden">
                                      Please select an image
                                    </div>
                                    <span
                                      id="file-upload-btn"
                                      class="btn btn-primary"
                                    >
                                      Select a file
                                    </span>
                                  </div>
                                  <div id="response" class="hidden">
                                    <div id="messages"></div>
                                  </div>
                                </label>
                              </>
                            ) : (
                              <div className="row ml-1">
                                <img
                                  src={serviceimage}
                                  Style="height:200px; width:100%;"
                                />
                                {/* <div Style=""> */}
                                <button
                                  //  className="btn"
                                  onClick={deleteReportimage}
                                  Style="position:absolute; z-index:1; border:0px; right:0;  top:0; padding-top:25px;outline: none; box-shadow: none; background-color: transparent;"
                                >
                                  <img
                                    src={require("../assets/images/close.png")}
                                    Style="height:25px; width:25px;"
                                  />
                                </button>
                                {/* </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card shadow">
                        <div className="card-header">
                          <div className="row" Style="align-items: center;">
                            <h2 className="m-0 col-md-10 font-weight-bold text-dark">
                              User Account
                            </h2>
                            <div
                              className="col-md-2"
                              Style="text-align: -webkit-right;"
                            >
                              <button
                                className="btn btn-primary"
                                onClick={() => navigate("/addaccount")}
                              >
                                Add Account
                              </button>
                              {/* <div className="select-focus">
                          <label
                            className="h6 focus-label"
                            Style="font-weight: bold"
                          >
                            User Role
                          </label>
                          <Select
                            className="floating font-weight-bold text-grey m-0"
                            options={options}
                            onChange={(e) => onchangeorderstatus(e)}
                            value={orderstatus}
                          />
                        </div> */}
                            </div>
                            {/* <Select className="m-0 col-md-3 font-weight-bold text-dark" options={options} onChange={(e)=>onchangeorderstatus(e)}/> */}
                          </div>
                        </div>
                        <div></div>
                        <div className="card-body">
                          <div className="table-responsive">
                            <table
                              className="table table-bordered"
                              id="dataTable"
                              width="100%"
                              cellSpacing="0"
                            >
                              <thead>
                                <tr>
                                  <th>Account id</th>
                                  <th>User id</th>
                                  <th>Graph id</th>
                                  <th>Account Number</th>
                                  <th>Account Balance</th>
                                  <th>Daily Profit</th>
                                  <th>Profit</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              {userdatas.map((item, index) => (
                                <tbody key={index}>
                                  <tr>
                                    <td>{item.account_id}</td>
                                    <td>
                                      {item.user_id
                                        ? item.user_id
                                        : "Not Filled"}
                                    </td>
                                    <td>
                                      {item.graph_id
                                        ? item.graph_id
                                        : "Not Filled"}
                                    </td>
                                    <td>
                                      {item.account_no
                                        ? item.account_no
                                        : "Not Filled"}{" "}
                                    </td>
                                    <td>
                                      {item.account_balance
                                        ? "$" + item.account_balance
                                        : "$0.00"}
                                    </td>
                                    <td>
                                      {item.daily_profit
                                        ? "$" + item.daily_profit
                                        : "$0.00"}
                                    </td>
                                    <td>
                                      {item.profit
                                        ? "$" + item.profit
                                        : "$0.00"}
                                    </td>
                                    <td className="justify-content-evenly">
                                      <button
                                        title="Edit Account!"
                                        className={"btn btn-outline-info m-1"}
                                        onClick={() =>
                                          localStorage.setItem(
                                            "accountid",
                                            item.account_id.toString()
                                          ) || navigate("/editaccount")
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={solid("pencil")}
                                        />
                                      </button>

                                      <button
                                        className="btn btn-outline-info m-1"
                                        onClick={() =>
                                          onDelete(item.account_id)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={solid("trash")}
                                        />
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              ))}
                            </table>
                            {/* <nav aria-label="...">
                              <ul className="pagination justify-content-end">
                                <li
                                  className={
                                    curr_page === 1
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={() =>
                                      setCurrPage(curr_page - 1) ||
                                      getaccountlist(curr_page - 1)
                                    }
                                  >
                                    <span aria-hidden="true">&laquo;</span>
                                    <span class="sr-only">Previous</span>
                                  </a>
                                </li>

                                <li className={"page-item"}>
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={() => setCurrPage(curr_page)}
                                  >
                                    {curr_page}
                                  </a>
                                </li>
                                <li
                                  className={
                                    curr_page === totalpage
                                      ? "page-item disabled"
                                      : "page-item"
                                  }
                                >
                                  <a
                                    className="page-link"
                                    href="#"
                                    onClick={() =>
                                      setCurrPage(curr_page + 1) ||
                                      getaccountlist(curr_page + 1)
                                    }
                                  >
                                    <span aria-hidden="true">&raquo;</span>
                                    <span class="sr-only">Next</span>
                                  </a>
                                </li>
                              </ul>
                            </nav> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-6 p-3 py-5">
                      <div class="card-block">
                        <h6
                          class="m-b-20 p-b-5 b-b-default f-w-600"
                          Style="font-weight: 600; font-size:20px; color:#121A50;"
                        >
                          User Information
                        </h6>
                        <hr />
                        <div className="row my-2">
                          {userdata.email_id ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px; "
                              >
                                Email
                              </p>
                              <h6 class="text-muted f-w-400">
                                {userdata.email_id}
                              </h6>
                            </div>
                          ) : null}
                          {userdata.phone_number ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px;"
                              >
                                Phone
                              </p>
                              <h6 class="text-muted f-w-400">
                                {userdata.phone_number}
                              </h6>
                            </div>
                          ) : null}
                          {userdata.city ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px;"
                              >
                                City
                              </p>
                              <h6 class="text-muted f-w-400">
                                {userdata.city}
                              </h6>
                            </div>
                          ) : null}
                          {userdata.zip_code ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px;"
                              >
                                Zip Code
                              </p>
                              <h6 class="text-muted f-w-400">
                                {userdata.zip_code}
                              </h6>
                            </div>
                          ) : null}
                        </div>
                        <h6
                          class="m-b-20 p-b-5 b-b-default f-w-600"
                          Style="font-weight: 600; font-size:20px; color:#121A50;"
                        >
                          User Money Details
                        </h6>
                        <hr />
                        <div className="row">
                          {userdata.total_balance  || userdata.total_balance === 0 ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px;"
                              >
                                Total amount
                              </p>
                              <h6 class="text-muted f-w-400">
                                $ {userdata.total_balance}
                              </h6>
                            </div>
                          ) : null}
                           {userdata.pending_balance || userdata.pending_balance === 0 ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px;"
                              >
                                Deposit Pending amount
                              </p>
                              <h6 class="text-muted f-w-400">
                                $ {userdata.pending_balance}
                              </h6>
                            </div>
                          ) : null}
                        </div>

                        <div className="row">
                          {userdata.total_withdraw_amount  || userdata.total_withdraw_amount === 0 ? (
                            <div class="col-sm-6">
                              <p
                                class="m-b-10 f-w-600 text-dark"
                                Style="font-weight: bold; font-size:15px;"
                              >
                                Withdraw amount
                              </p>
                              <h6 class="text-muted f-w-400">
                                $ {userdata.total_withdraw_amount}
                              </h6>
                            </div>
                          ) : null}
                          </div>

                        <ul class="social-link list-unstyled m-t-40 m-b-10">
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="facebook"
                              data-abc="true"
                            >
                              <i
                                class="mdi mdi-facebook feather icon-facebook facebook"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="twitter"
                              data-abc="true"
                            >
                              <i
                                class="mdi mdi-twitter feather icon-twitter twitter"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href="#!"
                              data-toggle="tooltip"
                              data-placement="bottom"
                              title=""
                              data-original-title="instagram"
                              data-abc="true"
                            >
                              <i
                                class="mdi mdi-instagram feather icon-instagram instagram"
                                aria-hidden="true"
                              ></i>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
