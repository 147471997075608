import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { SIGN_IN_API } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [myValidation, setMyValidation] = useState(0);
  const [show, setShow] = useState(false);
  const [isLoaded, setLoaded] = useState(false);
  console.log(isLoaded);
  const [error, setErr] = useState("");
  const utoken = localStorage.getItem("token");
  useEffect(() => {
    // if (utoken) {
    //   navigate("/dashboard");
    // } else {
    //   navigate("/login");
    // }
  }, []);

  const onsubmit = (e) => {
    // navigate("/dashboard");
    setLoaded(true);
    console.log(email);
    console.log(password);
    if (!email) {
      setMyValidation(1);
      setLoaded(false);
    } else if (!password) {
      setMyValidation(2);
      setLoaded(false);
    } else {
      setMyValidation(0);
      fetch(SIGN_IN_API, {
        method: "POST",
        headers: {
          "content-type": "application/json",
          accept: "application/json",
        },
        body: JSON.stringify({
          email_id: email,
          password: password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("response", data);
          if (data.Status === 0) {
            setErr("");
            setMyValidation(3);
            setErr("Email or Password does't match our records");
          } else if (data.Status === 1) {
            setErr("");
            setMyValidation(4);
            setErr(data.Message);
            console.log("my user_id id.....->",data.UserToken.toString());
            localStorage.setItem('token',data.UserToken.toString());
            navigate("/dashboard");
          }
          setLoaded(false);
        })
        .catch((err) => {
          console.log(JSON.stringify("error", err));
          setLoaded(false);
        });
    }
  };

  return (
    <div className="container">
      <div className="row justify-content-center" style={{ marginTop: 120 }}>
        <div className="col-xl-10 col-lg-12 col-md-9">
          <div className="card o-hidden border-0 shadow-lg my-5">
            <div className="card-body p-0">
              <div className="row">
                <div className="col-lg-6 d-none d-lg-block bg-login-image1">
                </div>
                <div className="col-lg-6">
                  <div className="p-5">
                    <div className="text-center">
                      <h1
                        className="h1 text-bold mb-4 bold"
                        Style="color:#0063AF;"
                      >
                        IPT
                      </h1>
                      <h1 className="h4 text-gray-900 mb-4">Welcome Back!</h1>
                    </div>
                    {myValidation === 1 ? (
                      <div className="alert alert-danger alert-block">
                        {/* <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                        >
                          ×
                        </button> */} 
                        <strong>Please Fill Email</strong>
                      </div>
                    ) : myValidation === 2 ? (
                      <div className="alert alert-danger alert-block">
                        {/* <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                        >
                          ×
                        </button> */}
                        <strong>Please Fill Password</strong>
                      </div>
                    ) : myValidation === 3 ? (
                      <div className="alert alert-danger alert-block">
                        {/* <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                        >
                          ×
                        </button> */}
                        <strong>{error}</strong>
                      </div>
                    ) : myValidation === 4 ? (
                      <div className="alert alert-success alert-block">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                        >
                          ×
                        </button>
                        <strong>{error}</strong>
                      </div>
                    ) : null}
                    <div className="user">
                      <div className="form-group">
                        <input
                          type="email"
                          className="form-control form-control-user"
                          value={email}
                          onChange={(text) =>
                            setEmail(text.target.value.replace(/\s/g, ""))
                          }
                          id="exampleInputEmail"
                          aria-describedby="emailHelp"
                          placeholder="Enter Email Address..."
                        />
                      </div>
                      <div className="form-group input-group mb-3">
                        <input
                          type={show ? "text" : "password"}
                          value={password}
                          onChange={(text) =>
                            setPassword(text.target.value.replace(/\s/g, ""))
                          }
                          className="form-control form-control-user"
                          id="exampleInputPassword"
                          placeholder="Password"
                        />
                        <div class="input-group-append">
                          <button
                            class="btn"
                            Style="background-color:#0063AF; color:#ffffff;"
                            onClick={() =>
                              show ? setShow(false) : setShow(true)
                            }
                          >
                            <FontAwesomeIcon
                              className="rounded-circle"
                              style={{
                                width: "100%",
                                justifyContent: "center",
                              }}
                              icon={show ? solid("eye-slash") : solid("eye")}
                              size="1x"
                            />
                          </button>
                        </div>
                      </div>
                      <button
                        // href="index.html"
                        Style="background-color:#0063AF; color:#ffffff; font-weight:bold; font-size:18px;"
                        className="btn btn-user btn-block"
                        onClick={(e) => (isLoaded === true ? {} : onsubmit(e))}
                      >
                        {isLoaded === true ? (
                          <span
                            class="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : (
                          "Login"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
