import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { GET_AND_EDIT_USER_API, imgUrl } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import moment from "moment";
import { countries } from "country-data";

export default function Addthirdparty() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [username, setThirdpartyusername] = useState("");
  const [password, setThirdpartypassword] = useState("");
  const [name, setname] = useState("");
  const [emailid, setemailid] = useState("");
  const [countrycode, setcountrycode] = useState("in");
  const [phonenumber, setphonenumber] = useState("");
  const [apinumber, setApinumber] = useState("");
  const [dob, setdob] = useState("");
  const [image, setimage] = useState("");
  const [img, setImg] = useState("");

  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);
  var user_id = localStorage.getItem("id");

  useEffect(() => {
    if (utoken) {
      navigate("/edituser");
      getuserdetails();
    } else {
      navigate("/login");
    }
  }, []);

  const getuserdetails = () => {
    console.log(utoken);
    setLoaded(true);
    var formData = new FormData();
    formData.append("user_id", user_id);
    fetch(GET_AND_EDIT_USER_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        user_id: user_id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          var Data = data.info;
          setThirdpartyusername(Data.thirdparty_login_id);
          setThirdpartypassword(Data.thirdparty_password);
          setname(Data.user_name);
          setemailid(Data.email_id);
          console.log(Data.country_code);
          if(Data.country_code){
            setcountrycode(Data.country_code.toLowerCase());
            setphonenumber(countries[Data.country_code.toString()]
            .countryCallingCodes[0] +
          " " +
           Data.phone_number);
          }
          if (Data.profile_pic) {
            setimage(imgUrl + Data.profile_pic);
          }
          if (Data.date_of_birth) {
            setdob(moment(Data.date_of_birth).format("YYYY-MM-DD"));
          }
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  
  const edituser = (e) => {
    console.log("called");
    e.preventDefault();
    setLoaded(true);
    var formData = new FormData();
    formData.append("user_id", user_id);
    formData.append("user_name", name);
    formData.append("email_id", emailid);
    formData.append("country_code", countrycode.toUpperCase());
    formData.append("phone_number", apinumber);
    formData.append("date_of_birth", dob);
    formData.append("profile_pic", img);
    if(username != "null" && password != "null" && username != null && password != null){
      formData.append("thirdparty_login_id", username);
    formData.append("thirdparty_password", password);
    }
    fetch(GET_AND_EDIT_USER_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          const Toast = swal1.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal1.stopTimer);
              toast.addEventListener("mouseleave", swal1.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: data.Message,
          });
        } else if (data.Status === 1) {
          const Toast = swal1.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal1.stopTimer);
              toast.addEventListener("mouseleave", swal1.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "User Edited Successfully",
          });
          navigate("/users");
          localStorage.removeItem("id");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  function handleChange(e) {
    console.log(e.target.files);
    setImg(e.target.files[0]);
    setimage(URL.createObjectURL(e.target.files[0]));
  }

  function removeimage() {
    setImg("");
    setimage("");
  }

  function onchangedob(e) {
    console.log(e.target.value);
    setdob(e.target.value);
  }

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  function onchangephonenumber(phone, country, e, v) {
    console.log(country.countryCode);
    console.log('e',e);
    console.log('v',v);
    var correctvalue = v.split(' ')[1];
    console.log('correct',v.split(' ')[1]);
    console.log('phone',phone);
    setcountrycode(country.countryCode);
    setphonenumber(v);
    setApinumber(correctvalue);
  }

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/users" className="text-dark">
                Users
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit User
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={edituser}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/users")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button>
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          Edit User
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Profile Pic *
                          </label>
                          <div id="file-upload-form" class="uploader">
                            {!image ? (
                              <>
                                <input
                                  id="file-upload"
                                  type="file"
                                  name="fileUpload"
                                  accept="image/*"
                                  onChange={handleChange}
                                />

                                <label for="file-upload" id="file-drag">
                                  <div id="start">
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                    <div>Select a file or drag here</div>
                                    <div id="notimage" class="hidden">
                                      Please select an image
                                    </div>
                                    <span
                                      id="file-upload-btn"
                                      class="btn btn-primary"
                                    >
                                      Select a file
                                    </span>
                                  </div>
                                  <div id="response" class="hidden">
                                    <div id="messages"></div>
                                  </div>
                                </label>
                              </>
                            ) : (
                              <div className="row ml-1">
                                <img
                                  src={image}
                                  Style="height:200px; width:100%;"
                                />
                                {/* <div Style=""> */}
                                <button
                                  //  className="btn"
                                  onClick={removeimage}
                                  Style="position:absolute; z-index:1; border:0px; right:0;  top:0; padding-top:25px;outline: none; box-shadow: none; background-color: transparent;"
                                >
                                  <img
                                    src={require("../assets/images/close.png")}
                                    Style="height:25px; width:25px;"
                                  />
                                </button>
                                {/* </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Username
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="name"
                            value={name}
                            onChange={(e) => setname(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Email Address
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email"
                            value={emailid}
                            onChange={(e) => setemailid(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                          />
                        </div>
                      </div>
                      <div className="row mt-3 justify-content-between">
                        <div className="col-md-4">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Phone Number
                          </label>
                          <PhoneInput
                            country={countrycode}
                            inputStyle={{ height: "50px", color: "black" }}
                            containerStyle={{ height: "50px" }}
                            value={phonenumber}
                            countryCodeEditable={false}
                            enableSearch={true}
                            placeholder="Phone Number"
                            disableCountryCode={false}
                            onChange={(phone, country, e, v) =>
                              onchangephonenumber(phone, country, e, v)
                            }
                          />
                        </div>
                        <div className="col-md-7">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Date of Birth
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            placeholder="Date Of Birth"
                            value={dob}
                            onChange={onchangedob}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Thirdparty Username *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Thirdparty Username"
                            value={username}
                            onChange={(e) =>
                              setThirdpartyusername(e.target.value)
                            }
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Thirdparty Password *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Thirdparty Password"
                            value={password}
                            onChange={(e) =>
                              setThirdpartypassword(e.target.value)
                            }
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="mt-5 mr-2 text-center col-md-3">
                          <button
                            className="btn btn-success"
                            Style="width:100%;"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
