import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { GET_ABOUTUS, EDIT_ABOUTUS, imgUrl } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Addaccount() {
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [description, setDescription] = useState();
  const [categoryimage, setCategoryimage] = useState();
  const [imgurl, setImgurl] = useState();
  const [img, setImg] = useState();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/aboutus");
      getAboutus();
    } else {
      navigate("/login");
    }
  }, []);

  const getAboutus = () => {
    const formData = new FormData();
    formData.append("about_us_id", 1);
    setLoaded(true);
    fetch(GET_ABOUTUS, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setCategoryimage(imgUrl + data.info.about_image);
          setImgurl(data.info.about_image);
          var text = data.info.about_description;
          setDescription(text);
        }
        setLoaded(false);
      })
      .catch((err) => {
        //console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const Editaboutus = (e) => {
    e.preventDefault();
    setLoaded(true);
    //console.log(description);
    const formData = new FormData();
    formData.append("about_us_id", 1);
    formData.append("about_image", img);
    formData.append("old_about_image", imgurl);
    formData.append("about_description", description);
    fetch(EDIT_ABOUTUS, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        //console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          // let avgpage = parseInt(data.total_page)/10;
          const Toast = swal1.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal1.stopTimer);
              toast.addEventListener("mouseleave", swal1.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: "Edit Aboutus Successfully",
          });
        }
        setLoaded(false);
      })
      .catch((err) => {
        //console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  function handleChange(e) {
    //console.log(e.target.files);
    setImg(e.target.files[0]);
    setCategoryimage(URL.createObjectURL(e.target.files[0]));
  }

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content" Style="background-color: #eaedf3;">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/dashboard" className="text-dark">
                Dashboard
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              {"Aboutus"}
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="grey"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              <div
                className="card"
                Style="border-radius:30px; box-shadow: 0px 0px 0px 0px grey;"
              >
                <form onSubmit={Editaboutus}>
                  <div
                    className="card-header py-3"
                    Style="background-color: #fafbfc; border-top-left-radius: 30px; border-top-right-radius: 30px;"
                  >
                    <div className="row" Style="justify-content:space-between;">
                      <div className="col" Style="margin-left:10px;">
                        <div className="row">
                          <i className="fas fa-users fa-2x text-gray-300"></i>
                          <h3 className="m-0 col-md-9 font-weight-bold text-dark">
                            Aboutus
                          </h3>
                        </div>
                      </div>
                      <div>
                        <button
                          type="submit"
                          className="btn btn-light mx-2"
                          Style="background-color:#0063AF; color:#FFF; font-weight:bold; border-radius: 10px;  min-width: 120px;"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    className="card-body"
                    Style="padding-left:0px; padding-right:0px; text-align:-webkit-center;"
                  >
                    <div className="row">
                      <div
                        className="col-md-5"
                        Style="text-align: -webkit-center;"
                      >
                        <label
                          className="form-label bold"
                          Style="margin-left:20px; color:#0063AF;"
                        >
                          Aboutus Image *
                        </label>
                        <div id="file-upload-form" class="uploader">
                          {!categoryimage ? (
                            <>
                              <input
                                id="file-upload"
                                type="file"
                                name="fileUpload"
                                accept="image/*"
                                onChange={handleChange}
                              />

                              <label for="file-upload" id="file-drag">
                                <div id="start">
                                  <i
                                    class="fa fa-download"
                                    aria-hidden="true"
                                  ></i>
                                  <div>Select a file or drag here</div>
                                  <div id="notimage" class="hidden">
                                    Please select an image
                                  </div>
                                  <span
                                    id="file-upload-btn"
                                    class="btn btn-primary"
                                  >
                                    Select a file
                                  </span>
                                </div>
                                <div id="response" class="hidden">
                                  <div id="messages"></div>
                                </div>
                              </label>
                            </> 
                          ) : (
                            <div className="row ml-1">
                              <img
                                src={categoryimage}
                                Style="height:300px; width:100%;"
                              />
                              {/* <div Style=""> */}
                              <button
                                //  className="btn"
                                onClick={() => setCategoryimage()}
                                Style="position:absolute; z-index:1; border:0px; right:0; padding-right:40px; top:0; padding-top:25px;outline: none; box-shadow: none; background-color: transparent;"
                              >
                                <img
                                  src={require("../assets/images/close.png")}
                                  Style="height:25px; width:25px;"
                                />
                              </button>
                              {/* </div> */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className="col-md-6 "
                        Style="text-align: -webkit-left;"
                      >
                        <label
                          className="form-label bold"
                          Style="margin-left:20px; color:#0063AF;"
                        >
                          Aboutus Description *
                        </label>
                        <CKEditor
                          editor={ClassicEditor}
                          config={{
                            toolbar: ["heading", "bold", "italic"],
                          }}
                          data={description}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setDescription(data);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
