import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";
import {
  LIST_SCREENS,
  DELETE_SCREEN,
  imgUrl,
} from "../components/ApiUrl";
import ReactHtmlParser from 'react-html-parser';

export default function Onboarding() {
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/manageonboarding");
      getscreenslist();
    } else {
      navigate("/login");
    }
  }, []);

  const getscreenslist = () => {
    setLoaded(true);
    fetch(LIST_SCREENS, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  //Delete Swall
  const onDelete = (id,img) => {
    const BODY = {
      screen_id: id,
      old_screen_image : img,
    };
    swal({
      title: "Delete Screen",
      text: "Are you sure want to Delete this Screen ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(DELETE_SCREEN, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              getscreenslist();
              swal("Poof! Your imaginary Screen has been Deleted!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Screen is Stay Safe");
      }
    });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">
                  Dashboard
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
              Onboarding screens
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                {/* <h5 className="h5 mb-2 text-gray-800">Users</h5> */}

                <div className="card shadow mb-4">
                  <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-10 font-weight-bold text-dark">
                        Onboarding screens
                      </h2>
                      <div className="col-md-2" Style="text-align: -webkit-right;">
                      <button className="btn btn-primary" onClick={()=>navigate('/AddScreen')}>Add Screen</button>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Screen Id</th>
                            <th>Screen Text</th>
                            <th>Screen Image</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {userdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.screen_id}</td>
                              <td>
                                {item.screen_text ? ReactHtmlParser(item.screen_text) : "Not Filled"}{" "}
                              </td>
                              <td className="justify-content-center">
                                {item.screen_image ? (
                                  <img
                                    src={imgUrl + item.screen_image}
                                    // alt="Avatar"
                                    class="mx-auto d-block"
                                    Style="height:80px; width:80px;"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                    icon={solid("user-circle")}
                                    size="5x"
                                  />
                                )}
                              </td>
                              <td className="justify-content-evenly">
                                  <button
                                    className="btn btn-outline-info m-1"
                                    onClick={() =>
                                      localStorage.setItem(
                                        "screenid",
                                        item.screen_id.toString()
                                      ) || navigate("/EditScreen")
                                    }
                                  >
                                    <FontAwesomeIcon icon={solid("pencil")} />
                                  </button>
                                  <button
                                  className="btn btn-outline-info m-1"
                                  onClick={() => onDelete(item.screen_id,item.screen_image)}
                                >
                                  <FontAwesomeIcon icon={solid("trash")} />
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
