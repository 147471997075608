import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";
import {
  LIST_WITHDRAWAL_API,
  UPDATE_WITHDRAWAL_STATUS,
  imgUrl,
} from "../components/ApiUrl";
import moment from "moment";

export default function Withdrawal() {
  const [curr_page, setCurrPage] = useState(1);
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [paymentdata, setPaymentdata] = useState([]);
  const [totalpage, setTotalpage] = useState(1);
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/Withdrawal");
      getWithdrawallist(curr_page);
    } else {
      navigate("/login");
    }
  }, []);

  const getWithdrawallist = (page) => {
    setLoaded(true);
    fetch(LIST_WITHDRAWAL_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        page_no: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setTotalpage(data.total_page);
          setPaymentdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  //Delete Swall
  const onApprove = (id) => {
    const BODY = {
      withdraw_request_id: id,
      withdraw_status: 1,
    };
    swal({
      title: "Approve Withdrawal",
      text: "Are you sure want to Approve this Withdrawal ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(UPDATE_WITHDRAWAL_STATUS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              getWithdrawallist(curr_page);
              swal("Poof! Your imaginary Withdrawal has been Approved!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Withdrawal is Stay Pending");
      }
    });
  };

  const onReject = (id) => {
    const BODY = {
      withdraw_request_id: id,
      withdraw_status: 2,
    };
    swal({
      title: "Reject Withdrawal",
      text: "Are you sure want to Reject this Withdrawal ?",
      icon: "error",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(UPDATE_WITHDRAWAL_STATUS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              getWithdrawallist(curr_page);
              swal("Poof! Your imaginary Withdrawal has been Rejected!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Withdrawal is Stay Pending");
      }
    });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">
                  Dashboard
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Withdrawal
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                {/* <h5 className="h5 mb-2 text-gray-800">Users</h5> */}

                <div className="card shadow mb-4">
                  <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-9 font-weight-bold text-dark">
                        Withdraw List
                      </h2>
                      {/* <div className="col-md-3">
                        <div className="select-focus">
                          <label
                            className="h6 focus-label"
                            Style="font-weight: bold"
                          >
                            User Role
                          </label>
                          <Select
                            className="floating font-weight-bold text-grey m-0"
                            options={options}
                            onChange={(e) => onchangeorderstatus(e)}
                            value={orderstatus}
                          />
                        </div>
                      </div> */}
                      {/* <Select className="m-0 col-md-3 font-weight-bold text-dark" options={options} onChange={(e)=>onchangeorderstatus(e)}/> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Withdraw Id</th>
                            <th>Name</th>
                            <th>Transaction Type</th>
                            <th>Transaction Amount</th>
                            <th>Payment Approved</th>
                            <th>Profile Image</th>
                            <th>Withdraw On</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {paymentdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.withdraw_request_id}</td>
                              <td>
                                {item.user_name ? item.user_name : "Not Filled"}{" "}
                              </td>
                              <td>
                                {
                                  // item.transaction_type === 0 ? 'Card' :
                                  "Bank"
                                }
                              </td>
                              <td>
                                {item.amount ? "$" + item.amount : "Not Filled"}
                              </td>
                              {/* <td>{item.age ? item.age : "Not Filled"}</td>
                                    <td>{item.city ? item.city : "Not Filled"}</td> */}
                              <td Style="text-align: -webkit-center;">
                                {item.withdraw_status === 0 ? (
                                  <i
                                    class="fa fa-times-circle"
                                    Style="font-size:30px;color:red;"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-check-circle"
                                    Style="font-size:30px;color:green;"
                                  ></i>
                                )}
                              </td>
                              <td className="justify-content-center">
                                {item.profile_pic ? (
                                  <img
                                    src={imgUrl + item.profile_pic}
                                    alt="Avatar"
                                    class="avatar mx-auto d-block"
                                    Style="height:80px; width:80px;"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                    icon={solid("user-circle")}
                                    size="5x"
                                  />
                                )}
                              </td>
                              <td>{moment(item.created_at).format("ll")}</td>
                              <td className="justify-content-evenly">
                                {item.withdraw_status === 0 ? (
                                  <>
                                    <button
                                      className="btn btn-outline-warning m-1"
                                      onClick={() =>
                                        onApprove(item.withdraw_request_id)
                                      }
                                    >
                                      Pending
                                    </button>
                                    <button
                                      className="btn btn-outline-danger m-1"
                                      onClick={() =>
                                        onReject(item.withdraw_request_id)
                                      }
                                    >
                                      Reject
                                    </button>{" "}
                                  </>
                                ) : (
                                  <button
                                    className="btn btn-outline-success m-1"
                                    disabled
                                  >
                                    Approved
                                  </button>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <nav aria-label="...">
                        <ul className="pagination justify-content-end">
                          <li
                            className={
                              curr_page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page - 1) ||
                                getWithdrawallist(curr_page - 1)
                              }
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>

                          <li className={"page-item"}>
                            <a
                              className="page-link"
                              href="#"
                              onClick={() => setCurrPage(curr_page)}
                            >
                              {curr_page}
                            </a>
                          </li>
                          <li
                            className={
                              curr_page === totalpage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page + 1) ||
                                getWithdrawallist(curr_page + 1)
                              }
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
