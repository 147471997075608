import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { GET_BANK,EDIT_BANK } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";

export default function Addbank() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [bankname, setBankname] = useState("");
  const [bankaccountnumber, setAccountNumber] = useState("");
  const [bankholdername, setHoldername] = useState("");
  const [bankcountryname, setCountryName] = useState("");
  const [bankifsccode, setIfsccode] = useState("");
  const [bankbranchname, setBranchName] = useState("");
  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);
  var bankid = localStorage.getItem("bank_id");

  useEffect(() => {
    if (utoken) {
      navigate("/Editbank");
      getbankdetails();
    } else {
      navigate("/login");
    }
  }, []);


  const getbankdetails = () => {
    setLoaded(true);

    var BODY = {
      bank_id : bankid,
    };
    fetch(GET_BANK, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
            const Toast = swal1.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", swal1.stopTimer);
                  toast.addEventListener("mouseleave", swal1.resumeTimer);
                },
              });
    
              Toast.fire({
                icon: "error",
                title: data.Message,
              });
        } else if (data.Status === 1) {
         var Data = data.info;
         setBankname(Data.bank_name);
         setAccountNumber(Data.bank_account_no);
         setHoldername(Data.bank_account_name);
         setCountryName(Data.country);
         setIfsccode(Data.ifsc_code);
         setBranchName(Data.branch_name);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };


  const editbank = (e) => {
    console.log("called");
    e.preventDefault();
    setLoaded(true);

    var BODY = {
      bank_id : bankid,
      bank_name:bankname,
      bank_account_name : bankholdername,
      bank_account_no : bankaccountnumber,
      ifsc_code : bankifsccode,
      branch_name : bankbranchname,
      country : bankcountryname
    };
    fetch(EDIT_BANK, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
            const Toast = swal1.mixin({
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", swal1.stopTimer);
                  toast.addEventListener("mouseleave", swal1.resumeTimer);
                },
              });
    
              Toast.fire({
                icon: "success",
                title: "Edit Bank Successfully",
              });
              navigate("/Banks");
              localStorage.removeItem("bank_id");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/Banks" className="text-dark">
                Banks
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Edit Bank
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={editbank}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/Banks")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button>
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          Edit Bank
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Bank Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bank Name"
                            value={bankname}
                            onChange={(e) => setBankname(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Bank Account Number *
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Bank Account Number"
                            value={bankaccountnumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Bank Holder Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bank Holder Name"
                            value={bankholdername}
                            onChange={(e) => setHoldername(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Bank Country Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bank Country Name"
                            value={bankcountryname}
                            onChange={(e) => setCountryName(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Bank IFSC Code *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bank IFSC Code"
                            value={bankifsccode}
                            onChange={(e) => setIfsccode(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Bank Branch Name *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Bank Branch Name"
                            value={bankbranchname}
                            onChange={(e) => setBranchName(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="mt-4 mr-2 text-center col-md-3">
                          <button
                            className="btn btn-primary"
                            Style="width:100%;"
                            type="submit"
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}