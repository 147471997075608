import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";
import { LIST_REQUEST_API,UPDATE_REQUEST_STATUS } from "../components/ApiUrl";
import Select from "react-select";

export default function Users() {
  const [curr_page, setCurrPage] = useState(1);
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [totalpage, setTotalpage] = useState();
  const [menu, setMenu] = useState(false);
  const [requeststatus, setRequeststatus] = useState({
    label:'Pending',
    value:1
  });

const options = [
  {label:'Pending', value:1},
  {label:'Completed', value:2}
]

  useEffect(() => {
    if (utoken) {
      navigate("/Support");
      getrequestlist(curr_page,requeststatus.value);
    } else {
      navigate("/login");
    }
  }, []);

  const onchangeorderstatus = (e) => {
    setRequeststatus(e);
    getrequestlist(curr_page, e.value);
  };

  const getrequestlist = (page,type) => {
    setLoaded(true);
    fetch(LIST_REQUEST_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        page_no: page,
        type:type,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          // let avgpage = parseInt(data.total_page)/10;
          // let count = Math.ceil(data.count);
          setTotalpage(data.total_page);
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  //Delete Swall
  const onCompleted = (id) => {
    const BODY = {
      ticket_id: id,
    };
    swal({
      title: "Completed",
      text: "Are you sure want to Completed this Request ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(UPDATE_REQUEST_STATUS, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              getrequestlist(curr_page,requeststatus.value);
              swal("Poof! Your imaginary Request has been Completed!", {
                icon: "success",
              });
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal("Your imaginary Request is On");
      }
    });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">Dashboard</a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Support Request
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                {/* <h5 className="h5 mb-2 text-gray-800">Users</h5> */}

                <div className="card shadow mb-4">
                <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-10 font-weight-bold text-dark">
                      SupportList
                      </h2>
                      <div className="col-md-2">
                        <div className="select-focus">
                          <label
                            className="h6 focus-label"
                            Style="font-weight: bold"
                          >
                            Request Status
                          </label>
                          <Select
                            className="floating font-weight-bold text-grey m-0"
                            options={options}
                            onChange={(e) => onchangeorderstatus(e)}
                            value={requeststatus}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>Support Request id</th>
                            <th>User Id</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Subject</th>
                            <th>Phone Number</th>
                            <th>Request Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {userdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.ticket_id}</td>
                              <td>
                                {item.ticket_created_by ? item.ticket_created_by : "Not Filled"}
                              </td>
                              <td>
                                {item.name ? item.name : "Not Filled"}
                              </td>
                              <td>
                                {item.email ? item.email : "Not Filled"}
                              </td>
                              <td>
                                {item.subject ? item.subject : "Not Filled"}
                              </td>
                              <td>
                                {item.phone_number ? item.phone_number : "Not Filled"}
                              </td>
                               <td Style="text-align: -webkit-center;">
                                {item.is_completed === 0 ? <i class="fa fa-times-circle" Style="font-size:30px;color:red;"></i> : <i class="fa fa-check-circle" Style="font-size:30px;color:green;"></i>}
                              </td>
                              <td className="justify-content-evenly">

                                <button
                                  className="btn btn-outline-info m-1"
                                  onClick={() =>
                                    localStorage.setItem(
                                      "ticket_id",
                                      item.ticket_id.toString()
                                    ) || navigate("/Sendsupportfeedback")
                                  }
                                >
                                  {item.is_completed === 1 ? <FontAwesomeIcon icon={solid("eye")} /> : <FontAwesomeIcon icon={solid("comment-alt")} />}
                                </button>
                                {item.is_completed === 0 ?
                                <button title="Chat Complated!"
                                  className="btn btn-outline-info m-1"
                                  onClick={() => onCompleted(item.ticket_id)}
                                >
                                  <FontAwesomeIcon icon={solid("check-circle")} />
                                </button>: null}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      <nav aria-label="...">
                        <ul className="pagination justify-content-end">
                          <li
                            className={
                              curr_page === 1
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page - 1) ||
                                getrequestlist(curr_page - 1,requeststatus.value)
                              }
                            >
                              <span aria-hidden="true">&laquo;</span>
                              <span class="sr-only">Previous</span>
                            </a>
                          </li>
                            <li
                              className={
                                  "page-item"
                              }
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() =>setCurrPage(curr_page)}
                              >
                                {curr_page}
                              </a>
                            </li>
                          <li
                            className={
                              curr_page === totalpage
                                ? "page-item disabled"
                                : "page-item"
                            }
                          >
                            <a
                              className="page-link"
                              href="#"
                              onClick={() =>
                                setCurrPage(curr_page + 1) ||
                                getrequestlist(curr_page + 1,requeststatus.value)
                              }
                            >
                              <span aria-hidden="true">&raquo;</span>
                              <span class="sr-only">Next</span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}