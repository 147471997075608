import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { LIST_DOCUMENT, ADD_KYC, imgUrl } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";
import Select from "react-select";
var pdfimage = require("../assets/images/PDFplaceholder.png");

export default function Editkyc() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [doc1number, setdoc1number] = useState("");
  const [doc2number, setdoc2number] = useState("");
  const [kycimg1, setkycimg1] = useState("");
  const [kycimg2, setkycimg2] = useState("");
  const [docid1, setdocid1] = useState("1");
  const [docid2, setdocid2] = useState("2");
  const [docinfoid1, setdocinfoid1] = useState("1");
  const [docinfoid2, setdocinfoid2] = useState("2");
  const [extfile1, setextfile1] = useState("");
  const [extfile2, setextfile2] = useState("");
  const [documentdata1, setdocumentdata1] = useState([]);
  const [documentdata2, setdocumentdata2] = useState([]);
  const [img1, setImg1] = useState();
  const [img2, setImg2] = useState();
  const utoken = localStorage.getItem("token");
  const userid = localStorage.getItem("uid");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
        getdocumets1();
        getdocumets2();
    } else {
      navigate("/login");
    }
  }, []);

  const getdocumets1 = () => {
    setLoaded(true);
    fetch(LIST_DOCUMENT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        type: 1,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setdocumentdata1(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const getdocumets2 = () => {
    setLoaded(true);
    fetch(LIST_DOCUMENT, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        type: 2,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          setdocumentdata2(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const Adduserkycdata = (e) => {
    const Toast = swal1.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", swal1.stopTimer);
        toast.addEventListener("mouseleave", swal1.resumeTimer);
      },
    });
    console.log("called");
    e.preventDefault();

    const formData = new FormData();
    formData.append("user_id", userid);
    formData.append("doc_pic1", img1);
    formData.append("doc_pic2", img2);
    formData.append("doc_no1", doc1number);
    formData.append("doc_no2", doc2number);
    formData.append("doc_id1", docid1);
    formData.append("doc_id2", docid2);

    fetch(ADD_KYC, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          Toast.fire({
            icon: "error",
            title: data.Message,
          });
        } else if (data.Status === 1) {
          Toast.fire({
            icon: "success",
            title: "Add Kyc Successfully",
          });
          navigate("/users");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  function handleChange(e) {
    console.log(e.target.files[0].name);
    var filename = e.target.files[0].name;
    var split1 = filename.split(".");
    var ext1 = split1[split1.length - 1];
    console.log("file 1 ->>", ext1);
    setextfile1(ext1);
    setImg1(e.target.files[0]);
    setkycimg1(URL.createObjectURL(e.target.files[0]));
  }

  function handleChange1(e) {
    console.log(e.target.files[0].name);
    var filename = e.target.files[0].name;
    var split1 = filename.split(".");
    var ext1 = split1[split1.length - 1];
    console.log("file 1 ->>", ext1);
    setextfile2(ext1);
    setImg2(e.target.files[0]);
    setkycimg2(URL.createObjectURL(e.target.files[0]));
  }

  function selectdoctype1(e) {
    console.log("your selectd option value is ->>>", e);
    setdocinfoid1(e);
    setdocid1(e.value);
  }

  function selectdoctype2(e) {
    console.log("your selectd option value is ->>>", e);
    setdocinfoid2(e);
    setdocid2(e.value);
  }

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/users" className="text-dark">
                Users
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page"> 
              Add Kyc
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={Adduserkycdata}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/users")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button>
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          Add Kyc
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Id Proof Doc *
                          </label>
                          <Select
                            className="floating font-weight-bold text-grey m-0"
                            options={documentdata1}
                            onChange={(e) => selectdoctype1(e)}
                            value={docinfoid1}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Id Proof Image *
                          </label>
                          <div id="file-upload-form" class="uploader">
                            {!kycimg1 ? (
                              <>
                                <input
                                  id="file-upload"
                                  type="file"
                                  name="fileUpload"
                                  accept="application/pdf,image/*"
                                  onChange={handleChange}
                                />

                                <label for="file-upload" id="file-drag">
                                  <div id="start">
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                    <div>Select a file or drag here</div>
                                    <div id="notimage" class="hidden">
                                      Please select an image
                                    </div>
                                    <span
                                      id="file-upload-btn"
                                      class="btn btn-primary"
                                    >
                                      Select a file
                                    </span>
                                  </div>
                                  <div id="response" class="hidden">
                                    <div id="messages"></div>
                                  </div>
                                </label>
                              </>
                            ) : (
                              <div className="row ml-1">
                                {extfile1 === "pdf" ? (
                                  <img
                                    src={pdfimage}
                                    Style="height:250px; width:100%;"
                                  />
                                ) : (
                                  <img
                                    src={kycimg1}
                                    Style="height:200px; width:100%;"
                                  />
                                )}
                                {/* <div Style=""> */}
                                <button
                                  //  className="btn"
                                  onClick={() => setkycimg1()}
                                  Style="position:absolute; z-index:1; border:0px; right:0;  top:0; padding-top:25px;outline: none; box-shadow: none; background-color: transparent;"
                                >
                                  <img
                                    src={require("../assets/images/close.png")}
                                    Style="height:25px; width:25px;"
                                  />
                                </button>
                                {/* </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Id Proof Number *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Id Proof Number"
                            value={doc1number}
                            onChange={(e) => setdoc1number(e.target.value)}
                            Style="border:0px; background-color:#fff; height:40px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Address Proof Doc *
                          </label>
                          <Select
                            className="floating font-weight-bold text-grey m-0"
                            options={documentdata2}
                            onChange={(e) => selectdoctype2(e)}
                            value={docinfoid2}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Address Proof Image *
                          </label>
                          <div id="file-upload-form" class="uploader">
                            {!kycimg2 ? (
                              <>
                                <input
                                  id="file-upload"
                                  type="file"
                                  name="fileUpload"
                                  accept="application/pdf,image/*"
                                  onChange={handleChange1}
                                />

                                <label for="file-upload" id="file-drag">
                                  <div id="start">
                                    <i
                                      class="fa fa-download"
                                      aria-hidden="true"
                                    ></i>
                                    <div>Select a file or drag here</div>
                                    <div id="notimage" class="hidden">
                                      Please select an image
                                    </div>
                                    <span
                                      id="file-upload-btn"
                                      class="btn btn-primary"
                                    >
                                      Select a file
                                    </span>
                                  </div>
                                  <div id="response" class="hidden">
                                    <div id="messages"></div>
                                  </div>
                                </label>
                              </>
                            ) : (
                              <div className="row ml-1">
                                {extfile2 === "pdf" ? (
                                  <img
                                    src={pdfimage}
                                    Style="height:250px; width:100%;"
                                  />
                                ) : (
                                  <img
                                    src={kycimg2}
                                    Style="height:200px; width:100%;"
                                  />
                                )}
                                {/* <div Style=""> */}
                                <button
                                  //  className="btn"
                                  onClick={() => setkycimg2()}
                                  Style="position:absolute; z-index:1; border:0px; right:0;  top:0; padding-top:25px;outline: none; box-shadow: none; background-color: transparent;"
                                >
                                  <img
                                    src={require("../assets/images/close.png")}
                                    Style="height:25px; width:25px;"
                                  />
                                </button>
                                {/* </div> */}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Address Proof Number *
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address Proof Number"
                            value={doc2number}
                            onChange={(e) => setdoc2number(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>

                      <div className="row justify-content-center">
                        <div className="mt-4 mr-2 text-center col-md-3">
                          <button
                            className="btn btn-primary"
                            Style="width:100%;"
                            type="submit"
                          >
                            Add Kyc
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
