import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { ADD_SCREEN } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function Addscreen() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [screentext, setscreentext] = useState("");
  const [serviceimage, setserviceimage] = useState();
  const [img, setImg] = useState();
  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/AddScreen");
    } else {
      navigate("/login");
    }
  }, []);


  const addscreen = (e) => {
    const Toast = swal1.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", swal1.stopTimer);
          toast.addEventListener("mouseleave", swal1.resumeTimer);
        },
      });
    console.log("called");
    e.preventDefault();

    const formData = new FormData();
    formData.append("screen_image", img);
    formData.append("screen_text", screentext);

    serviceimage ? fetch(ADD_SCREEN, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          const Toast = swal1.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", swal1.stopTimer);
              toast.addEventListener("mouseleave", swal1.resumeTimer);
            },
          });

          Toast.fire({
            icon: "error",
            title: data.Message,
          });
        } else if (data.Status === 1) {
              Toast.fire({
                icon: "success",
                title: "Add Screen Successfully",
              });
              navigate("/manageonboarding");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      }): Toast.fire({
        icon: "error",
        title: "Please Upload Image",
      }) ;
  };


  function handleChange(e) {
    console.log(e.target.files);
    setImg(e.target.files[0]);
    setserviceimage(URL.createObjectURL(e.target.files[0]));
  }


  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/manageonboarding" className="text-dark">
               Onboarding screens
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Add Screen
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={addscreen}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/manageonboarding")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button>
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          Add Screen
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12" >
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Screen Image *
                          </label>
                          <div id="file-upload-form" class="uploader">
                                {!serviceimage ?     
                                <>
                                <input
                                    id="file-upload"
                                    type="file"
                                    name="fileUpload"
                                    accept="image/*"
                                    onChange={handleChange}
                                  />

                                  <label for="file-upload" id="file-drag">
                                    <div id="start">
                                      <i
                                        class="fa fa-download"
                                        aria-hidden="true"
                                      ></i>
                                      <div>Select a file or drag here</div>
                                      <div id="notimage" class="hidden">
                                        Please select an image
                                      </div>
                                      <span
                                        id="file-upload-btn"
                                        class="btn btn-primary"
                                      >
                                        Select a file
                                      </span>
                                    </div>
                                    <div id="response" class="hidden">
                                      <div id="messages"></div>
                                    </div>
                                  </label>
                                  </>:
                                   <div className="row ml-1">
                                   <img
                                     src={serviceimage}
                                     Style="height:200px; width:100%;"
                                   />
                                   {/* <div Style=""> */}
                                   <button
                                    //  className="btn"
                                     onClick={() => setserviceimage()}
                                     Style="position:absolute; z-index:1; border:0px; right:0;  top:0; padding-top:25px;outline: none; box-shadow: none; background-color: transparent;"
                                   >
                                    <img src={require("../assets/images/close.png")} Style="height:25px; width:25px;" />
                                   </button>
                                   {/* </div> */}
                                 </div>
                                  }
                                  
                                </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Screen Text *
                          </label>
                          <CKEditor
                          editor={ClassicEditor}
                          config={{
                            toolbar: ["heading", "bold", "italic"],
                          }}
                          data={screentext}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setscreentext(data);
                          }}
                        />
                          {/* <textarea
                            type="text"
                            className="form-control"
                            placeholder="Screen Text"
                            rows="5"
                            value={screentext}
                            onChange={(e) => setscreentext(e.target.value)}
                            Style="border:0px; background-color:#fff; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          /> */}
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="mt-4 mr-2 text-center col-md-3">
                          <button
                            className="btn btn-primary"
                            Style="width:100%;"
                            type="submit"
                          >
                            Add Screen
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}