import React from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";

export default function pagenotfound() {
  return (
    <>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content"  Style="margin:300px;">
            <div class="container-fluid">
            <div class="text-center">
              <div class="error mx-auto" data-text="404">
                404
              </div>
              <p class="lead text-gray-800 mb-5">Page Not Found</p>
              <p class="text-gray-500 mb-0">
                It looks like you found a glitch in the matrix...
              </p>
              <a href="/dashboard">&larr; Back to Dashboard</a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
