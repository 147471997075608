import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { SEND_DEPOSIT_REQUEST_API } from "../components/ApiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RotatingLines } from "react-loader-spinner";
import swal1 from "sweetalert2";

export default function Addaccount() {
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [depositamount, setdepositamount] = useState("");
  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);
  var user_id = localStorage.getItem("uid");

  useEffect(() => {
    if (utoken) {
      if (user_id) {
        navigate("/senddepositrequest");
      } else {
        navigate("/users");
      }
    } else {
      navigate("/login");
    }
  }, []);

  const Toast = swal1.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", swal1.stopTimer);
      toast.addEventListener("mouseleave", swal1.resumeTimer);
    },
  });

  const senddepositrequest = (e) => {
    console.log("called");
    e.preventDefault();
    setLoaded(true);

    var BODY = {
      request_to: user_id,
      amount: depositamount,
    };
    fetch(SEND_DEPOSIT_REQUEST_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify(BODY),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          Toast.fire({
            icon: "success",
            title: data.Message,
          });
          navigate("/users");
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item">
              <a href="/users" className="text-dark">
                Users
              </a>
            </li>
            <li class="breadcrumb-item active" aria-current="page">
              Send Deposit Request
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0000BF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              {/* <div className=" rounded bg-white m-10 mt-5"> */}
              <div className="row justify-content-center">
                <div
                  className="col-md-6 mb-4"
                  Style="border-radius:20px; background-color : #fff;"
                >
                  <div
                    className="p-3"
                    style={{ marginLeft: 30 }}
                    Style="border-radius:20px; background-color : #fff;"
                  >
                    <form onSubmit={senddepositrequest}>
                      <div className="d-flex justify-content-start align-items-center mb-3">
                        <button
                          className="btn btn-outline-primary"
                          type="button"
                          onClick={() => navigate("/users")}
                        >
                          <FontAwesomeIcon icon={solid("arrow-left")} />
                        </button>
                        <h4
                          className="text-right"
                          Style="margin:7px 0px 5px 20px;"
                        >
                          Send Deposit Request
                        </h4>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <label
                            className="labels"
                            Style="color:#0063AF; font-size:15px;"
                          >
                            Deposit Amount *
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Deposit Amount"
                            value={depositamount}
                            onChange={(e) => setdepositamount(e.target.value)}
                            Style="border:0px; background-color:#fff; height:50px; border-radius:5px; box-shadow: 0 0 5pt 0.5pt #D3D3D3; padding-left:20px;"
                            required
                          />
                        </div>
                      </div>
                      <div className="row justify-content-center">
                        <div className="mt-5 mr-2 text-center col-md-3">
                          <button
                            className="btn btn-primary"
                            Style="width:100%;"
                            type="submit"
                          >
                            Send Request
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
