import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { useNavigate } from "react-router-dom";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro";
import { RotatingLines } from "react-loader-spinner";
import swal from "sweetalert";
import { countries } from "country-data";
import {
  LIST_USERS_API,
  GET_AND_EDIT_USER_API,
  SEARCH_USER,
  imgUrl,
} from "../components/ApiUrl";

export default function Users() {
  const [curr_page, setCurrPage] = useState(1);
  const navigate = useNavigate();
  const utoken = localStorage.getItem("token");
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const [totalpage, setTotalpage] = useState();
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    if (utoken) {
      navigate("/users");
      setLoaded(true);
      getuserslist(curr_page);
    } else {
      navigate("/login");
    }
  }, []);

  function handleChange(e) {
    e.preventDefault();
    console.log(e.target.value);
    var val = e.target.value;
    // setLoaded(true);
    val.length === 0
      ? getuserslist(1)
      : fetch(SEARCH_USER, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "content-type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify({
            email_or_name: e.target.value,
          }),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              setTotalpage(0);
              setUserdata(data.info);
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
  }

  // const onchangeorderstatus = (e) => {
  //   setOrderstatus(e);
  //   getuserslist(curr_page, e.value);
  // };

  const getuserslist = (page) => {
    setCurrPage(page);
    // setLoaded(true);
    fetch(LIST_USERS_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
      body: JSON.stringify({
        page_no: page,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          // let avgpage = parseInt(data.total_page)/10;
          // let count = Math.ceil(data.count);
          setTotalpage(data.total_page);
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  //Block Swall
  const onBlock = (bflaf, id) => {
    let flag = bflaf === 1 ? 2 : 1;

    const BODY = {
      user_id: id,
      is_block: flag,
    };
    console.log("block called", JSON.stringify(BODY));
    swal({
      title: bflaf === 1 ? "blocked User" : "unblocked user",
      text:
        bflaf === 1
          ? "Are you sure want to block this User ?"
          : "Are you sure want to unblock this user",
      icon: bflaf === 1 ? "warning" : "info",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        fetch(GET_AND_EDIT_USER_API, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${utoken}`,
            "Content-Type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify(BODY),
        })
          .then((response) => response.json())
          .then((data) => {
            console.log("response", data);
            if (data.Status === 0) {
              //
            } else if (data.Status === 1) {
              getuserslist(curr_page);
              swal(
                bflaf === 1
                  ? "Poof! Your imaginary User has been blocked!"
                  : "Poof! Your imaginary User has been unblocked!",
                {
                  icon: "success",
                }
              );
            }
            setLoaded(false);
          })
          .catch((err) => {
            console.log(JSON.stringify("error", err));
            setLoaded(false);
          });
      } else {
        swal(
          bflaf === 1
            ? "Your imaginary User is Safe!"
            : "Your imaginary user is blocked!"
        );
      }
    });
  };

  //Delete Swall
  const onDelete = (id) => {
    // const BODY = {
    //   user_id: id,
    // };
    // swal({
    //   title: "Delete User",
    //   text: "Are you sure want to Delete this User ?",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // }).then((willDelete) => {
    //   if (willDelete) {
    //     fetch(DELETE_USER_API, {
    //       method: "POST",
    //       headers: {
    //         Authorization: `Bearer ${utoken}`,
    //         "Content-Type": "application/json",
    //         accept: "application/json",
    //       },
    //       body: JSON.stringify(BODY),
    //     })
    //       .then((response) => response.json())
    //       .then((data) => {
    //         console.log("response", data);
    //         if (data.Status === 0) {
    //           //
    //         } else if (data.Status === 1) {
    //           getuserslist(curr_page);
    //           swal("Poof! Your imaginary User has been Deleted!", {
    //             icon: "success",
    //           });
    //         }
    //         setLoaded(false);
    //       })
    //       .catch((err) => {
    //         console.log(JSON.stringify("error", err));
    //         setLoaded(false);
    //       });
    //   } else {
    //     swal("Your imaginary User is Safe");
    //   }
    // });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <>
      <div id="wrapper">
        <ul
          className={
            menu
              ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
              : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
          }
          id="accordionSidebar"
        >
          <Sidebar />
          <div class="text-center d-none d-md-inline">
            <button
              className="rounded-circle border-0"
              id="sidebarToggle"
              onClick={() => toggleMobileMenu()}
            ></button>
          </div>
        </ul>
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <Header onMenuClick={(value) => toggleMobileMenu()} />
            <ol class="breadcrumbs">
              <li class="breadcrumb-item">
                <a href="/dashboard" className="text-dark">
                  Dashboard
                </a>
              </li>
              <li class="breadcrumb-item active" aria-current="page">
                Users
              </li>
            </ol>
            {isLoaded === true ? (
              <div class="loader">
                <RotatingLines
                  strokeColor="#0063AF"
                  height={50}
                  width={50}
                  strokeWidth="1"
                />
              </div>
            ) : (
              <div className="container-fluid">
                {/* <h5 className="h5 mb-2 text-gray-800">Users</h5> */}

                <div className="card shadow mb-4">
                  <div className="card-header py-3 ">
                    <div className="row" Style="align-items: center;">
                      <h2 className="m-0 col-md-9 font-weight-bold text-dark">
                        UserList
                      </h2>
                      <div className="col-md-3">
                        <div class="form-floating">
                          <label for="floatingInput">Search User</label>
                          <input
                            type="text"
                            class="form-control"
                            id="floatingInput"
                            placeholder="Search here..."
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {/* <Select className="m-0 col-md-3 font-weight-bold text-dark" options={options} onChange={(e)=>onchangeorderstatus(e)}/> */}
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive">
                      <table
                        className="table table-bordered"
                        id="dataTable"
                        width="100%"
                        cellSpacing="0"
                      >
                        <thead>
                          <tr>
                            <th>User id</th>
                            <th>Email</th>
                            <th>Email Verified</th>
                            <th>Name</th>
                            <th>Thirdparty Id</th>
                            <th>Thirdparty Password</th>
                            <th>Profile Image</th>
                            <th>Phone Number</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        {userdata.map((item, index) => (
                          <tbody key={index}>
                            <tr>
                              <td>{item.user_id}</td>
                              <td>
                                {item.email_id ? item.email_id : "Not Filled"}
                              </td>
                              <td Style="text-align: -webkit-center;">
                                {item.is_email_verified === 0 ? (
                                  <i
                                    class="fa fa-times-circle"
                                    Style="font-size:30px;color:red;"
                                  ></i>
                                ) : (
                                  <i
                                    class="fa fa-check-circle"
                                    Style="font-size:30px;color:green;"
                                  ></i>
                                )}
                              </td>
                              <td>
                                {item.user_name ? item.user_name : "Not Filled"}{" "}
                              </td>
                              <td>
                                {item.thirdparty_login_id
                                  ? item.thirdparty_login_id
                                  : "Pending"}
                              </td>
                              <td>
                                {item.thirdparty_password
                                  ? item.thirdparty_password
                                  : "Pending"}
                              </td>
                              <td className="justify-content-center">
                                {item.profile_pic ? (
                                  <img
                                    src={imgUrl + item.profile_pic}
                                    alt="Avatar"
                                    class="avatar mx-auto d-block"
                                    Style="height:80px; width:80px;"
                                  />
                                ) : (
                                  <FontAwesomeIcon
                                    style={{
                                      width: "100%",
                                      justifyContent: "center",
                                    }}
                                    icon={solid("user-circle")}
                                    size="5x"
                                  />
                                )}
                              </td>
                              <td>
                                {item.phone_number && item.country_code
                                  ? countries[item.country_code.toString()]
                                      .countryCallingCodes[0] +
                                    " " +
                                    item.phone_number
                                  : item.phone_number
                                  ? item.phone_number
                                  : "Not Filled"}
                              </td>

                              <td className="justify-content-evenly">
                                <button
                                  title="Edit User!"
                                  className={"btn btn-outline-info m-1"}
                                  onClick={() =>
                                    localStorage.setItem(
                                      "id",
                                      item.user_id.toString()
                                    ) || navigate("/edituser")
                                  }
                                >
                                  <FontAwesomeIcon icon={solid("pencil")} />
                                </button>

                                <button
                                  title="User Block!"
                                  className={
                                    item.is_block === 1
                                      ? "btn btn-outline-info m-1"
                                      : "btn btn-outline-danger m-1"
                                  }
                                  onClick={() =>
                                    onBlock(item.is_block, item.user_id)
                                  }
                                >
                                  {item.is_block === 2 ? (
                                    <FontAwesomeIcon
                                      icon={solid("ban")}
                                      size="1x"
                                    />
                                  ) : (
                                    <FontAwesomeIcon icon={regular("circle")} />
                                  )}
                                </button>

                                <button
                                  className="btn btn-outline-info m-1"
                                  onClick={() =>
                                    localStorage.setItem(
                                      "id",
                                      item.user_id.toString()
                                    ) || navigate("/profile")
                                  }
                                >
                                  <FontAwesomeIcon icon={solid("eye")} />
                                </button>
                                {item.is_kyc <= 0 ? (
                                  <button
                                    className="btn btn-outline-info m-1"
                                    onClick={() => {
                                      localStorage.setItem("uid", item.user_id);
                                      navigate("/addkyc");
                                    }}
                                  >
                                    Add Kyc
                                  </button>
                                ) : null}
                                <button
                                    className="btn btn-outline-info m-1"
                                    onClick={() => {
                                      localStorage.setItem("uid", item.user_id);
                                      navigate("/senddepositrequest");
                                    }}
                                  >
                                    Deposit
                                  </button>
                                  {/* <button
                                    className="btn btn-outline-info m-1"
                                    onClick={() => {
                                      localStorage.setItem("uid", item.user_id);
                                      navigate("/addkyc");
                                    }}
                                  >
                                    Withdrawal
                                  </button> */}
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                      {totalpage === 0 ? null : (
                        <nav aria-label="...">
                          <ul className="pagination justify-content-end">
                            <li
                              className={
                                curr_page === 1
                                  ? "page-item disabled"
                                  : "page-item"
                              }
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() =>
                                  setCurrPage(curr_page - 1) ||
                                  getuserslist(curr_page - 1)
                                }
                              >
                                <span aria-hidden="true">&laquo;</span>
                                <span class="sr-only">Previous</span>
                              </a>
                            </li>

                            <li className={"page-item"}>
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => setCurrPage(curr_page)}
                              >
                                {curr_page}
                              </a>
                            </li>
                            <li
                              className={
                                curr_page === totalpage
                                  ? "page-item disabled"
                                  : "page-item"
                              }
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() =>
                                  setCurrPage(curr_page + 1) ||
                                  getuserslist(curr_page + 1)
                                }
                              >
                                <span aria-hidden="true">&raquo;</span>
                                <span class="sr-only">Next</span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
