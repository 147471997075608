import React, { useEffect, useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate, useLocation } from "react-router-dom";
import { DASHBOARD_COUNT_API } from "../components/ApiUrl";

export default function Dashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoaded, setLoaded] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const utoken = localStorage.getItem("token");
  const [menu, setMenu] = useState(false);

  useEffect(() => {
    console.log("utoken =>>>>>>>>", utoken);
    if (utoken) {
      navigate("/dashboard");
      getcountlist();
    } else {
      navigate("/login");
    }
  }, []);

  const getcountlist = () => {
    setLoaded(true);
    fetch(DASHBOARD_COUNT_API, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${utoken}`,
        "content-type": "application/json",
        accept: "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("response", data);
        if (data.Status === 0) {
          //
        } else if (data.Status === 1) {
          console.log("info", data.info);
          setUserdata(data.info);
        }
        setLoaded(false);
      })
      .catch((err) => {
        console.log(JSON.stringify("error", err));
        setLoaded(false);
      });
  };

  const toggleMobileMenu = () => {
    setMenu(!menu);
  };

  return (
    <div id="wrapper">
      <ul
        className={
          menu
            ? "navbar-nav gradient-sidebar sidebar sidebar-dark accordion toggled"
            : "navbar-nav gradient-sidebar sidebar sidebar-dark accordion"
        }
        id="accordionSidebar"
      >
        <Sidebar />
        <div class="text-center d-none d-md-inline">
          <button
            className="rounded-circle border-0"
            id="sidebarToggle"
            onClick={() => toggleMobileMenu()}
          ></button>
        </div>
      </ul>
      <div id="content-wrapper" className="d-flex flex-column">
        <div id="content">
          <Header onMenuClick={(value) => toggleMobileMenu()} />
          <ol class="breadcrumbs">
            <li class="breadcrumb-item active" aria-current="page">
              Dashboard
            </li>
          </ol>
          {isLoaded === true ? (
            <div class="loader">
              <RotatingLines
                strokeColor="#0063AF"
                height={50}
                width={50}
                strokeWidth="1"
              />
            </div>
          ) : (
            <div className="container-fluid">
              <div className="d-sm-flex align-items-center justify-content-between mb-4">
                {/* <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>   */}
                {/* <a
          href="#"
          className="d-none d-sm-inline-block btn btn-sm btn-primary shadow-sm"
        >
          <i className="fas fa-download fa-sm text-white-50"></i> Generate
          Report
        </a> */}
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-3 mb-4">
                      <div className="card border-left-dark shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                Total Users
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                {userdata.Total_user}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-users fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                Total Deposit Amount
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                ${userdata.Total_amount}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-money-bill-alt fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div className="card border-left-warning shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                Total Deposit Pending Amount
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                ${userdata.Pending_amount}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-money-bill-alt fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div className="card border-left-info shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                Total Withdrawal Amount
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                ${userdata.Total_withdraw_amount ? userdata.Total_withdraw_amount : 0}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-money-bill-alt fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 mb-4">
                      <div className="card border-left-primary shadow h-100 py-2">
                        <div className="card-body">
                          <div className="row no-gutters align-items-center">
                            <div className="col mr-2">
                              <div className="text-xs font-weight-bold text-dark text-uppercase mb-1">
                                Total Withdrawal Pending Amount
                              </div>
                              <div className="h5 mb-0 font-weight-bold text-gray-800">
                                ${userdata.Pending_withdraw_amount ? userdata.Pending_withdraw_amount : 0}
                              </div>
                            </div>
                            <div className="col-auto">
                              <i className="fas fa-money-bill-alt fa-2x text-gray-300"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <Footer />
      </div>
    </div>
  );
}
